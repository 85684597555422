/* Marketing Section Start*/

.marketing {
    display: inline;
}

.marketing-template-editor {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
  
    .block-item {
      &.heading-txt {
        background-image: url(../images/marketing-template/heading-txt.jpg);
      }
  
      &.p-txt {
        background-image: url(../images/marketing-template/p-txt.jpg);
      }
  
      &.lower-heading-p-txt {
        background-image: url(../images/marketing-template/lower-heading-p-txt.jpg);
      }
  
      &.small-heading-p-txt {
        background-image: url(../images/marketing-template/small-heading-p-txt.jpg);
      }
  
      &.heading-p-txt {
        background-image: url(../images/marketing-template/heading-p-txt.jpg);
      }
  
      &.p-heading-txt {
        background-image: url(../images/marketing-template/p-heading-txt.jpg);
      }
  
      &.full-txt {
        background-image: url(../images/marketing-template/full-txt.jpg);
      }
  
      &.two-txt {
        background-image: url(../images/marketing-template/two-txt.jpg);
      }
  
      &.single-quot-text {
        background-image: url(../images/marketing-template/single-quot-text.png);
      }
  
      &.two-text-colons {
        background-image: url(../images/marketing-template/two-text-colons.jpg);
      }
  
      &.three-text-check {
        background-image: url(../images/marketing-template/three-text-check.png);
      }
  
      &.two-text-check {
        background-image: url(../images/marketing-template/two-text-check.png);
      }
  
      &.text-three {
        background-image: url(../images/marketing-template/text-three.jpg);
      }
  
      &.two-unorderedlist-img {
        background-image: url(../images/marketing-template/two-unorderedlist-img.png);
      }
  
      &.two-orderedlist-img {
        background-image: url(../images/marketing-template/two-orderedlist-img.png);
      }
  
      &.txt-left {
        background-image: url(../images/marketing-template/txt-left.jpg);
      }
  
      &.txt-right {
        background-image: url(../images/marketing-template/txt-right.jpg);
      }
  
      &.two-img {
        background-image: url(../images/marketing-template/two-img.jpg);
      }
  
      &.two-img-txt {
        background-image: url(../images/marketing-template/two-img-txt.jpg);
      }
  
      &.full-img {
        background-image: url(../images/marketing-template/full-img.jpg);
      }
  
      &.text-three-colons-img {
        background-image: url(../images/marketing-template/text-three-colons-img.jpg);
      }
  
      &.three-colons-img {
        background-image: url(../images/marketing-template/three-colons-img.jpg);
      }
  
      &.box-text-rgt-img {
        background-image: url(../images/marketing-template/box-text-rgt-img.jpg);
      }
  
      &.box-text-lft-img {
        background-image: url(../images/marketing-template/box-text-lft-img.jpg);
      }
  
      &.text-list-img {
        background-image: url(../images/marketing-template/text-list-img.jpg);
      }
  
      &.h-p-three-img {
        background-image: url(../images/marketing-template/h-p-three-img.jpg);
      }
  
      &.img-p-img-p-left {
        background-image: url(../images/marketing-template/img-p-img-p-left.jpg);
      }
  
      &.img-four {
        background-image: url(../images/marketing-template/img-four.jpg);
      }
  
      &.logo-top {
        background-image: url(../images/marketing-template/logo-top.png);
      }
  
      &.unsubscribe-template {
        background-image: url(../images/marketing-template/unsubscribe-template.jpg);
      }
  
      &.footer-plain {
        background-image: url(../images/marketing-template/footer-plain.jpg);
      }
  
      &.footer-two-col {
        background-image: url(../images/marketing-template/footer-two-col.jpg);
      }
  
      &.footer-two-col-ext {
        background-image: url(../images/marketing-template/footer-two-col-ext.jpg);
      }
  
      &.box-img-with-buttons-sec1 {
        background-image: url(../images/marketing-template/box-img-with-buttons-sec1.jpg);
      }
  
      &.box-img-with-buttons-sec2 {
        background-image: url(../images/marketing-template/box-img-with-buttons-sec2.jpg);
      }
  
      &.box-img-with-buttons-sec3 {
        background-image: url(../images/marketing-template/box-img-with-buttons-sec3.jpg);
      }
  
      &.box-img-with-buttons-sec5 {
        background-image: url(../images/marketing-template/box-img-with-buttons-sec5.jpg);
      }
  
      &.template-buttons {
        background-image: url(../images/marketing-template/template-buttons.jpg);
      }
  
      &.template-buttons-2 {
        background-image: url(../images/marketing-template/template-buttons-2.jpg);
      }
  
      &.two-column-buttons {
        background-image: url(../images/marketing-template/two-column-buttons.jpg);
      }
  
      &.three-column-buttons {
        background-image: url(../images/marketing-template/three-column-buttons.jpg);
      }
  
      &.lft-txt-4btn {
        background-image: url(../images/marketing-template/lft-txt-4btn.jpg);
      }
  
      &.social-icons {
        background-image: url(../images/marketing-template/social-icons.jpg);
      }
  
      &.youtube-full {
        background-image: url(../images/marketing-template/youtube-full.png);
      }
  
      &.map-small {
        background-image: url(../images/marketing-template/map-small.jpg);
      }
  
      &.map-full {
        background-image: url(../images/marketing-template/map-full.jpg);
      }
  
      .block-item-edt {
        display: none;
      }
    }
  }
  
  .block-item {
    &.gray-border-4 {
      background-image: url(../images/marketing-template/gray-border-4.jpg);
    }
  
    &.gray-border-5 {
      background-image: url(../images/marketing-template/gray-border-5.jpg);
    }
  
    &.gray-border-6 {
      background-image: url(../images/marketing-template/gray-border-6.jpg);
    }
  
    &.gray-border-8 {
      background-image: url(../images/marketing-template/gray-border-8.jpg);
    }
  
    &.gray-border-10 {
      background-image: url(../images/marketing-template/gray-border-10.jpg);
    }
  
    &.gray-border-12 {
      background-image: url(../images/marketing-template/gray-border-12.jpg);
    }
  
    &.active {
      border: dashed 1px $color-black;
    }
  }
  
  .marketing-editing-block {
    fill: unset;
    display: block;
    width: 600px;
    min-height: 400px;
    margin: 0 auto;
    margin-bottom: 100px;
    color: $color-black;
  
    .block-item {
      min-height: auto !important;
  
      .block-item-edt {
        display: block;
      }
    }
  }
  
  .marketing-full-edit {
    overflow: initial;
    margin-bottom: 200px;
    margin-top: 62px;
    position: relative;
  }
  
  /* 
  .marketing-editing-block-container {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden
  } */
  
  .tbl-icons .fa-grip-horizontal {
    cursor: grab;
  }
  
  .marketing-editing-block-container {
    background-color: $color-gray-2;
  }
  
  .template-block-droppable {
    height: 5px;
    background-color: $color-gray-2;
    color: $color-primary !important;
    font-size: 14px !important;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  
    &.ui-state-hover {
      background-color: $color-primary !important;
      color: $color-white !important;
    }
  
    &.ui-state-active {
      height: 28px;
      line-height: 28px;
    }
  }
  
  .marketing-template-editor .block-item {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100px;
    padding: 0px;
    border-bottom: solid 2px $color-gray-1;
    position: relative;
  }
  

.custom-bxs {
    padding-left: 70px;
    padding-right: 70px;
}

.custom-message .quote-lft {
    position: absolute;
    top: -24px;
    left: -40px;
}

.custom-message .quote-rgt {
    position: absolute;
    bottom: -24px;
    right: 40px;
}


/* Marketing Section  End*/

.template-block-from label {
    font-size: 13px;
    line-height: 24px;
    float: left;
    padding-right: 5px;
}

.template-block-from span {
    margin-right: 8px;
}

.marketing-editor {
    max-width: 260px;
    width: 260px;
    padding-left: 45px;
    position: relative;
    border-right: 2px solid $color-gray-1;
    overflow: auto;
}

.fixedbody {
    position: fixed;
}

.drag-helper {
    padding: 10px;
    font-weight: bold;
    color: $color-black;
    border: dashed 1px $color-secondary-hover;
    width: 210px;
    z-index: 99999;
    display: none;
    /*margin-top: 60px;*/
}

.colorpicker_blank {
    position: absolute;
    width: 20px;
    height: 21px;
    left: 296px;
    top: 142px;
    overflow: hidden;
    border: solid 0.5px $color-black;
    padding: 1px 3px;
    color: $color-danger;
    background-color: $color-black;
}


[name="TextBlock"],
[name="ImageBlock"],
[name="HeaderBlock"],
[name="ButtonBlock"],
[name="SocialBlock"],
[name="DividerBlock"],
[name="DynamicFieldsBlock"] {
    overflow: auto !important;
    height: calc(100vh - 70px);
}

.txtSize {
    min-width: 85px;
}

.TemplateSavedBasic .templateList.marketing-list li a {
  background-size: auto 100% !important;
  background-position: 50% !important;
  background-color: $color-gray-1 !important;
  background-repeat: no-repeat !important;
}

.marketing-editing-block{
    .active {
      position: relative;
      z-index: 2;
      outline: $color-black;
      outline-style: dashed;
      outline-width: 2px;
  }
  div[contenteditable] {
    outline: 0;
  }
}


#dynamictable .status-dropdown {
    padding: 0.25rem 0.25rem;
}



#iframeEdit {
    height: calc(100vh - 65px) !important;
    border: 0 !important;
}

[name="StyleBlock"] .col-auto {
    border: 1px solid $color-gray-1;
    border-radius: 0;
    color: $color-black;
    min-width: 24px;
    padding: 4px 10px;
}

.marketing-css-block {
    max-width: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 65px);
    box-sizing: border-box;
}

[name="StyleBlock"] .padding-area .col[type="number"] {
    max-width: 60px;
}

[name="StyleBlock"] .padding-area .col.lft-box {
    border-left: $color-black 2px solid;
}

[name="StyleBlock"] .padding-area .col.rgt-box {
    border-right: $color-black 2px solid;
}

[name="StyleBlock"] .padding-area .col.bot-box {
    border-bottom: $color-black 2px solid;
}

[name="StyleBlock"] .padding-area .col.top-box {
    border-top: $color-black 2px solid;
}

.block-row-template-edit {
    width: 38px;
    position: absolute;
    z-index: 99;
    top: 0;
    left: -38px;
    display: none;
}

.block-row-template-edit .fa.btn {
    max-width: 26px;
    width: 100%;
    height: 26px;
    padding-left: 0;
    padding-right: 0;
}

#img-template-edit {
    width: 30px;
    position: absolute;
    z-index: 3;
    color: $color-white;
    font-size: 18px;
    text-align: center;
    padding: 4px;
}

#img-template-resolution {
    position: absolute;
    z-index: 100;
    color: $color-white;
    font-size: 12px;
    text-align: center;
    padding: 4px;
}

a,
.lookup-display-record,
.email-section .email,
.check-details {
    color: $color-primary;
    cursor: pointer;
}

.lookup-display-disabled-record {
    color: $color-light;
    cursor: auto;
}

a:hover,
.lookup-display-record:hover
{
    text-decoration: underline;
}

.recipients-table.table {
    width: auto;
    th , td{
        white-space: pre !important;
    }
    td{
        max-width: fit-content;
    }
}

.templateList {
  &.marketing-list {
    padding: 0;

    li {
      list-style: none;
      position: relative;
      transition: all 0.2s;

      a {
        display: block;
        padding-bottom: 100%;
        background-size: 100% auto !important;
        transition: all 0.2s;
      }

      .marketing-preview, 
      .template-clone, 
      .template-delete {
        display: none;
        transition: all 0.4s;
        margin-bottom: 4px;
      }

      &:hover {
        .marketing-preview,
        .template-clone,
        .template-delete {
          display: block;
        }

        a {
          box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
          border-color: $color-primary;
        }

        .title {
          color: $color-primary;
        }

        .sub-title {
          color: $color-black !important;
        }
      }

      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.5;
      }

      .sub-title {
        transition: all 0.2s;
      }
    }
  }
}

.TemplateSavedBasic {
  .templateList {
    &.marketing-list {
      li {
        a {
          background-size: auto 100% !important;
          background-position: 50% !important;
          background-color: #eee !important;
          background-repeat: no-repeat !important;
        }
      }
    }
  }
}






/*=====================Create Campaign===========================*/

.campaign-container {
    width: 100%;

    .campaign {
        .nav-item {
            flex: auto;
            position: relative;
            font-size: 1.2rem;

            &:hover .nav-link,
            .nav-link:hover,
            .nav-link {
                border: 0;
            }

            &.selected {
                .nav-link {
                    color: $color-black;
                }

                .circle {
                    background: $color-black;
                }

                &::before,
                &::after {
                    background-color: $color-black;
                }

            }

            &.completed {
                .nav-link {
                    color: $color-primary;
                }

                .circle {
                    background: $color-primary;
                }

                &::before,
                &::after {
                    background-color: $color-primary;
                }
            }

            .circle {
                width: 30px;
                height: 30px;
                display: inline-block;
                border-radius: 50%;
                background: $color-secondary;
                color: $color-white;
                text-align: center;
            }

            .nav-link {
                color: $color-secondary;
            }

            &:first-child::before,
            &:last-child::after {
                display: none;
            }

            &::before,
            &::after {
                content: "";
                position: relative;
                flex: 1;
                margin: 0;
                height: 1px;
                background-color: rgba($color-black, 0.1);
                top: 50%;
            }
        }
    }

}

.campaign-type {
    label {
        padding: 2rem;
        width: 100%;
        display: block;
        border: 1px solid $color-primary;
        color: $color-primary;
        font-size: 1.4rem;
        position: relative;

        &.rounded.active {
            border: 1px solid $color-primary;
            color: $color-white;
            background: $color-primary;

            &::before {
                content: "\f00c";
                position: absolute;
                font-size: 1.6rem;
                color: $color-white;
                left: 14px;
                top: 30px;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }

        input {
            visibility: hidden;
        }
    }
}

.close-editor {
    position: fixed;
    right: 8px;
    top: 2px;
}

.bounced-emails-table .data-row[visible="false"],
.recipients-table .data-row[visible="false"],
.no-result {
    display: none;
}

.bounced-emails-table .data-row[visible="true"],
.recipients-table .data-row[visible="true"] {
    display: table-row;
}
.text-found-marketing-contact {
    background-color: rgba($color-success, 0.16);
}