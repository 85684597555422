.select2-container {
    font-weight: normal;
}

.select2-results__option {
    padding: 4px 1rem;
    position: relative;
    color: $color-light;
    -webkit-line-clamp: 1;

    &.select2-results__option--selectable.select2-results__option--highlighted {
        background-color: var(--primary);
        color: $color-white;
    }

    &.select2-results__option--selectable.select2-results__option--selected {
        background: var(--primary);
        color: $color-white;
    }

    // &:hover {
    //     background-color: var(--primary);
    //     color: $color-white;
    // }

    .fa,
    .fas {
        position: absolute;
        font-size: 11px;
    }

    .fa-check {
        left: 2px;
        top: 9px;
    }

    .fa-thumbtack {
        right: 2px;
        top: 9px;
    }
}

.select2-search__field:focus-visible,
.select2-selection.select2-selection--single.select2-selection--clearable:focus-visible {
    outline: 0px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    padding-bottom: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: $color-white;
    color: $color-secondary;
    display: inline-flex;
    justify-content: flex-start;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove.hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice:hover {
    background: $color-secondary;
    color: $color-white;
}

.select2-selection__choice__remove {
    background-color: transparent !important;
    border-right: 1px solid $color-gray-1;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice:hover .select2-selection__choice__remove {
    color: $color-white;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: $color-light-blue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($color-primary, 25%);
}

.select2-dropdown,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border: 1px solid $color-form-border;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    color: $color-secondary;
}

.select2-dropdown{
    z-index: 1052 ;
}

/* QPC-6341 Changes */
#onboard .carousel-item.slide-page-timezone .select2-container--default .select2-results > .select2-results__options {
    max-height: 80px;
    text-align: left;
    color: $color-black;
}

#onboard .carousel-item.slide-page-timezone .select2 .select2-selection__rendered {
    text-align: left;
    color: $color-dark-hover;
}

/* For selection2 customize options which may have long value */
.select2-customize-output {
    max-width: 100%;
    @include text-hidden;
    display: block;
}

.venue-section .select2-container--default .select2-selection--single .select2-selection__rendered {
    max-width: 170px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
    background-color: var(--primary) !important;
        color: $color-white !important;
}
.select2-container--default .select2-results__option--selected {
    background-color: $color-gray-1 !important;
    color: $color-black !important;
}



