.lblChk {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
    padding: 0;
    min-width: 16px;
    min-height: 16px;
    margin-top: 1px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark {
            background-color: $color-primary;
            border-color: rgba($color-primary, 0.205);
            box-shadow: 0 0 0 3px rgba($color-primary, 0.205);
            &:after {
                display: block !important;
            }
        }

        &:checked~.radioCheck:after {
            display: block !important;
            background: $color-white;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            top: 4px;
            left: 4px;
        }
    }
    &.disabled-checkbox input~.checkmark {
        box-shadow: 0 0 0 3px rgba($color-light, 0.205);
    }
    .checkmark::after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 8px;
        border: solid $color-white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    
}

.parent-lblChk .lblChk {
    display: block;
    margin-top: 8px;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $color-white;
    border: $color-secondary solid 1px;
    border-radius: 4px;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::after {
        content: "";
        position: absolute;
        display: none;
    }
}

[disabled] {
    color: $color-secondary;
}

.disabled {
    label {
        color: $color-gray-1;
        text-decoration: none;
    }
    .checkmark {
        background: $color-gray-2;
    }
    a:hover {
        text-decoration: none;
    }
    .checkmark:hover, .checkbox:hover .checkmark {
        background-color: $color-gray-2;
    }
    .lblChk:not(.disabled-checkbox):hover input~.checkmark {
        background-color: $color-gray-2;
    }
}

.event-filter-menu .lblChk,
.user-filter-menu .lblChk,
.query-list .lblChk,
.merge-account-fields .lblChk {
    line-height: 1.25;
    display: block;
    margin: 10px 0;
    padding: 0px 25px 1px;
    position: relative;
}
.listview-left-panel .filter-listing .lblChk {
    padding-left: 1.25rem;
}
.listview-display .table td .lblChk {
    margin: 2px 8px 2px 4px;
}
.listview-left-panel .filter-listing .checkmark {
    top: 2px;
}

@media (hover: hover) and (pointer: fine) {

    .lblChk:not(.disabled-checkbox):hover input~.checkmark {
        background-color: $color-primary;
        border-color: rgba($color-primary, 0.205);
    }
}