
/********** Shop Css Begins*****************/

.user-timing-info-row {
    text-align: center;
    cursor: pointer;
}

.user-timings {
    min-width: 100px;
}

.week-timing {
    border: 1px solid $color-gray-1;
    border-radius: 5px;
    padding: 6px;
}

.week-startdate {
    font-size: 13px;
}

.week-enddate {
    font-size: 13px;
}

.week-timing-display {
    padding: 0px 15px;
}

.staff-timing-calendar {
    position: absolute;
    z-index: 1060;
    visibility: visible;
    top: 30px !important;
    left: 80px !important;
}

.first-shift-timing {
    background-color: $color-primary;
    border-radius: 5px;
    color: $color-white;
    white-space: nowrap;
    padding: 4px;
    font-size: 12px;
    margin-bottom: 2px;
}

.second-shift-timing {
    background-color: $color-primary;
    border-radius: 5px;
    color: $color-white;
    white-space: nowrap;
    padding: 4px;
    font-size: 12px;
    margin-bottom: 2px;
}

.user-timing-staffname {
    color: $color-primary;
}

.user-timing-column {
    color: $color-primary;
    min-width: 130px;
}

.extended-bottom-appointment {
    border-bottom: dotted 2px $color-primary-hover !important;
}

.extended-top-appointment {
    border-top: dotted 2px $color-primary-hover !important;
}

.del-product-btn {
    color: $color-danger important;
}


.app-no-show {
    background-color: #ff5e5e !important;
    color: rgba($color-white, 0.9);
}

.app-confirmed {
    background-color: #FFFF00 !important;
}

.app-new {
    background-color: #a6e5bd !important;
    color: $color-black;
}

.app-checkout {
    background-color: #c7c7b5 !important;
    color: $color-white;
}


/********** Shop Css Ends*****************/
