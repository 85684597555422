.license-sec {
    top: -5px;
    width: auto;
    display: flex;
    z-index: 6;
    .progress {
        width: 40px;
        height: 40px;
        line-height: 150px;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 0 solid $color-white;
            position: absolute;
            top: 0;
            left: 0;
        }
        &>span{
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }
        .progress-left {
            left: 0;
            .progress-bar {
                left: 100%;
                border-top-right-radius: 80px;
                border-bottom-right-radius: 80px;
                border-left: 0;
                -webkit-transform-origin: center left;
                transform-origin: center left;
            }
        }
        .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 3px;
            border-style: solid;
            position: absolute;
            top: 0;
        }
        .progress-right {
            right: 0;

            .progress-bar {
                left: -100%;
                border-top-left-radius: 80px;
                border-bottom-left-radius: 80px;
                border-right: 0;
                -webkit-transform-origin: center right;
                transform-origin: center right;
                animation: loading-1 1.8s linear forwards;
            }
        }

        .progress-value {
            width: 90%;
            height: 90%;
            border-radius: 50%;
            font-size: 16px;
            color: $color-light;
            line-height: 40px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 5%;
            text-align: center;
        }
        &.blue{
            .progress-bar {
                border-color: $color-light-blue;
            }
            .progress-left .progress-bar {
                animation: loading-1 1.5s linear forwards 1.8s;
            }
        }
        &.yellow{
            .progress-bar {
                border-color: $color-warning-hover;
            }
            .progress-left .progress-bar {
                animation: loading-2 1s linear forwards 1.8s;
            }
        }
        &.pink{
            .progress-bar {
                border-color: rgba($color-danger, .8);
            }
            .progress-left .progress-bar {
                animation: loading-4 0.4s linear forwards 1.8s;
            }
        }
        &.green{
            .progress-bar {
                border-color: $color-success;
            }
            .progress-left .progress-bar {
                animation: loading-5 1.2s linear forwards 1.8s;
            }
            
        }
       
    }

    .progress-bar-per {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        background: rgba($color-white, 0.95);
        svg {
            width: 48px;
            position: relative;
            margin: auto;

            path {
                stroke: $color-primary;
                stroke-width: 8;
            }
        }
        #svgLicenseIssued path {
            stroke: $color-primary;
        }
        &::after{
            width: 36px;
            height: 36px;
            content: "";
            background: $color-white;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            position: absolute;
            border-radius: 50%;
        }

        .count {
            position: absolute;
            color: $color-primary;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 100;
            display: block;
        }
        
    }
}

.licence-hover {
    padding-bottom: 4px;
    transition: all .3s;
    border-radius: 5px;
    &:hover {
        box-shadow: 0 0 10px 1px rgba($color-dark, 0.1);
        cursor: pointer;

        .filename {
            color: $color-primary !important;
        }
    }
}

/* master-licence-issued - hover */
.master-license-issued {
    .master-licence-hover-tooltip {
        visibility: hidden;
        width: 50%;
        white-space: nowrap;
        background-color: $color-dark;
        color: $color-white;
        text-align: left;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
        top: 110%;
        /* left: 11%; */
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        &::after {
            content: "";
            position: absolute;
            bottom: 98%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $color-dark transparent transparent transparent;
        }
    }

    &:hover .master-licence-hover-tooltip {
        visibility: visible;
        opacity: 1;
    }

}

/* .feature-license-issued - hover*/

.feature-license-issued {
    .feature-licence-hover-tooltip {
        visibility: hidden;
        width: 50%;
        white-space: nowrap;
        background-color: $color-dark;
        color: $color-white;
        text-align: left;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
        top: 110%;
        /* left: 46%; */
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        &::after {
            content: "";
            position: absolute;
            bottom: 98%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $color-dark transparent transparent transparent;
        }
    }
    &:hover{
        .feature-licence-hover-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }
}


/* .app-license-issued - hover*/

.app-license-issued {
    .app-licence-hover-tooltip {
        visibility: hidden;
        width: 50%;
        white-space: nowrap;
        background-color: $color-dark;
        color: $color-white;
        text-align: left;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
        top: 110%;
        left: 51.5%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        ::after {
            content: "";
            position: absolute;
            bottom: 98%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $color-dark transparent transparent transparent;
        }
    }
    &:hover .app-licence-hover-tooltip {
        visibility: visible;
        opacity: 1;
    }
}

/* .domain-license-issued - hover*/

.domain-license-issued {
    .domain-licence-hover-tooltip {
        visibility: hidden;
        width: 50%;
        white-space: nowrap;
        background-color: $color-dark;
        color: $color-white;
        text-align: left;
        border-radius: 6px;
        padding: 5px 5px;
        position: absolute;
        z-index: 1;
        top: 110%;
        /* left: 80%; */
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        &::after {
            content: "";
            position: absolute;
            bottom: 98%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $color-dark transparent transparent transparent;
        }
    }
    &:hover .domain-licence-hover-tooltip {
        visibility: visible;
        opacity: 1;
    }
}


@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
}

@media (max-width:767px){
    .license-sec {
        top: unset;
        z-index: 0 !important;
    }

    .UserList .license-sec {
        display: none;
    }
}