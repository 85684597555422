.modal-2 {
    z-index: 1052;
}

.backdrop-2,
.backdrop-1 {
    z-index: 1051;
}

.modal-3 {
    z-index: 1053;
}

.backdrop-3 {
    z-index: 1052;
}

.modal-4 {
    z-index: 1054;
}

.backdrop-4 {
    z-index: 1053;
}

.modal-content {
    max-height: calc(100vh - 3.5rem);
    min-height: 300px;
}

.modal-body {
    overflow: auto;
}

.progress-bar-sec .modal-dialog {
    top: 50%;
    transform: translate(0, -50%) !important;
    margin-top: 0;
    margin-bottom: 0;
}

.modal-header .close {
    outline: 0;
}

.modal-header h5 {
    word-break: break-all;
}

.swal-modal {
    width: 500px;
}

#GridSettingModal .ui-sortable-handle {
    width: 100% !important;
}

.page-form .modal-body div:first-child h6 {
    margin-top: 0 !important;
}

.modal-replace-user .modal-sm {
    min-width: 500px !important;
}

.modal-min-snippit {
    max-width: 250px;
    width: 100%;
    text-align: left;
    width: auto;
    display: flex;
    @include text-hidden;
}

.modal-min-snippit span {
    max-width: 100%;
    text-align: left;
    width: auto;
    padding: 0.125rem;
    @include text-hidden;
}

.modal-min-height300 {
    min-height: 300px !important;
}

.otpScreen .modalDialog {
    min-width: 500px !important;
}

@media (min-width: 576px) {

    .modal-sm {
        min-width: 500px !important;
    }
}

@media (min-width: 768px) {

    .modal-dialog {
        min-width: 715px !important;
    }

    .employee-status-modal.modal-lg {
        min-width: 500px !important;
        max-width: 500px;
    }


}

@media (max-width: 767px) {

    .modal-dialog {
        min-width: unset !important;
    }

    .swal-modal {
        width: calc(100% - 20px) !important;
    }

}