/*=======Help=========*/

.help-search {
    max-width: 650px;
    position: relative;
    margin: 0 auto;
}

.help-search input {
    height: 50px;
    padding-left: 20px;
    padding-right: 40px;
    font-size: 18px;
}

.help-search::after {
    position: absolute;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    right: 16px;
    top: 10px;
    color: $color-secondary;
    font-size: 20px;
}

.help-topic-card {
    font-size: 20px;
    transition: all 0.2s;
}

.help-topic-card a {
    text-decoration: none;
    color: $color-black;

    .document-subtitle {
        color: $color-dark;
    }
}

.help-topic-card a i {
    display: inline-block;
    background-color: $color-primary;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    font-size: 24px;
    color: $color-white;
    margin-right: 10px;
    transition: all 0.2s;
}

.topic-text {
    flex: auto;
}

.topic-text .small {
    font-size: 0.875rem;
}

.help-topic-card:hover {
    border-color: $color-primary;
}

.help-topic-card:hover a i {
    background: $color-primary;
}

.help-document-detail .calendar-left-panel {
    max-width: 300px;
}

.help-detail-search {
    position: relative;
}

.help-detail-search::after {
    position: absolute;
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    right: 10px;
    top: 4px;
    color: $color-secondary;
    font-size: 16px;
}



.help-detail-search input {
    padding-right: 30px;
}

.help-detail-container {
    border-radius: 6px;
    background-color: $color-white;
    border-style: solid;
    border-width: 1px;
    border-color: $color-gray-1;
    padding: 2.5rem;
    height: 100%;
    overflow: auto;
    font-size: 0.875rem;
}

.operavipupcomingstay {
    color: $color-primary
}

.operavipupcomingstay:hover {
    text-decoration: underline;
}

.help-detail-container h1 {
    margin-bottom: 20px;
}

.help-detail-container ul li {
    padding: 0.2rem 0;
}

#help-tree,
#help-tree li {
    list-style-type: none;
}

#help-tree li {
    padding: 0.35rem 0rem;
    line-height: 1;
}

#help-tree li.active {
    color: $color-white;
    background: $color-primary;
}

#help-tree {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
}

#help-tree .caret {
    cursor: pointer;
    user-select: none;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    display: block;
}

#help-tree .caret::before {
    content: "\f054";
    color: $color-black;
    display: inline-block;
    margin-right: 6px;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    position: absolute;
}

#help-tree .caret-down::before {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: $color-primary;
}

#help-tree .nested {
    color: $color-black;
    padding-left: 16px;
}

#help-tree .nested.active {
    display: block;
}

#help-tree .nested.active li {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
}

#help-tree .nested-child {
    padding-left: 25px;
}

.help-left-panel {
    height: 100%;
}

.search-found {
    background-color: $color-primary;
}

.beta-nav-menu {
    background: $color-white;
    padding: 0px 3px 1px 3px;
    color: $color-white;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 13px !important;
    margin-bottom: auto;
}

.beta-nav-menu-compressed {
    position: absolute;
    top: 2px;
    right: 142px;
    font-size: 10px !important;
    background: $color-white;
    padding-left: 3px;
    padding-right: 3px;
    color: $color-white;
    border-radius: 2px;
    margin-left: 4px;
    padding-bottom: 1px;
}

.highlight {
    background-color: $color-primary;
    color: $color-white;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0 1px 4px rgba($color-black, 0.7);
    -webkit-box-shadow: 0 1px 4px rgba($color-black, 0.7);
    box-shadow: 0 1px 4px rgba($color-black, 0.7);
}

.highlight {
    padding: 1px 4px;
    margin: 0 -4px;
}

.mainMenuModuleName {
    word-break: break-word;
}


/********** HELP Css Start*****************/

.help-detail-container {
    overflow-y: scroll;
    height: calc(100vh - 20vh);
}

.word-count {
    position: absolute;
    right: 5px;
    top: 5px;
    background: $color-white;
    z-index: 2;
    padding: 1px;
}

.word-count .space {
    padding: 3px;
}

mark,
.mark {
    padding: unset;
}

.help-left-panel {
    max-width: 200px;
}

/**  QPC-8348 & QPC-8454 START **/
.search-box {
    margin: 0 auto;
    width: 600px;
}

.search-result {
    text-align: center !important;
}

.help-documents .d-none {
    display: none !important;
}

/** QPC-8348 & QPC-8454 END **/
/********** HELP Css End *****************/