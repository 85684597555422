/*===============================My Drive==================================*/

@mixin text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @mixin box-common-styles {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    margin-bottom: 0.9rem;
    cursor: pointer;
  
    .card {
      transition: all 0.17s;
    }
  
    &:hover {
      .card {
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
        color: $color-primary;
        border-color: $color-primary;
        i {
          color: $color-primary;
        }
      }
    }
  }

.main-drive {
    .folder-box {
       @include box-common-styles;
      
        &.favourite{
            .card-title{
                padding-right: 16px;
                position: relative;
                .fa-heart {
                    position: absolute;
                    right: 0;
                    top: 4px;
                }
            
            }
            .file-bg {
                position: relative;
            }
        }
        
    }
    .file-box{
        @include box-common-styles;
        &:hover {
            .card {
              i {
                color: $color-white !important;
              }
            }
          }
        .fa-heart {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 17px;
            height: 17px;
            background-color: $color-white;
            border-radius: 50%;
            padding: 3px 2px 2px 2px;
        }
       
    }

    .file-loader {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        margin-bottom: 0.9rem;
    }

    .file-bg {
        height: 100%;
        padding-bottom: calc(100% - 48px);
        background-size: cover !important;
        background-color: #f4f4f4 !important;
        position: relative;
        width: 100%;
        background-position: top center !important;

        &.default-icon {
            background-size: unset !important;
            background-position: center center !important;
        }
    }
    
}

.download-disabled {
    opacity: 0.5;
}

.gm-style .gm-svpc {
    display: none !important;
}

.main-drive .folder-box .card-title,    
.catname {
   @include text-ellipsis;
}

.main-drive .file-box .card-title {
    height: 48px;
    box-sizing: border-box;
    padding: 1rem;
    @include text-ellipsis;
}

.main-drive .file-box .card-title span {
    flex-grow: 1;
    max-width: 100%;
    @include text-ellipsis;
}

.main-drive .file-box .card-title a {
    opacity: 0;
    transition: all 0.3s;
}

.main-drive .file-box:hover .card-title a {
    opacity: 1;
}

.main-drive-grid,
.main-drive-list {
    height: calc(100vh - 190px);
    overflow: auto;
    overflow-x: hidden;
}

//added by ankush: for outline on selected file when clicked from email
.shared-active {
    //changed color of selected file: QPC-7125
    outline: 3px solid $color-danger;
}

.drive-selected {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    color: $color-primary !important;
    border-color: $color-primary !important;
}

.drive-selected.card i.fa-folder,
.drive-selected i.fa-folder.text-secondary {
    color: $color-primary !important;
}

.drive-rightclick-menu {
    padding: 0 !important;
}

.drive-rightclick-menu .dropdown-item {
    color: $color-light !important;
}

.drive-rightclick-menu .dropdown-item:active {
    color: $color-white !important;
}

.main-drive-grid .breadcrumb {
    background: none;
    border-radius: 0;
    font-size: 1rem;
    color: $color-primary;
}

.breadcrumb li {
    @include text-ellipsis;
    max-width: 100px;
}

.main-drive-grid .breadcrumb a {
    color: inherit;
    cursor: pointer;
}

.file-bg.fa::before {
    color: $color-secondary;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: xxx-large;
    right: 0;
    margin-top: -24px;
    margin-left: -16px;
}

.file-box:hover .file-bg.fa::before {
    color: $color-primary;
}

.main-drive-grid .breadcrumb li:last-child a {
    color: $color-secondary;
    cursor: none;
}

.gridfile-box i {
    font-size: x-large;
    color: $color-secondary;
}

.gridfile-box.drive-selected i {
    color: $color-primary !important;
}

.gridfile-box td>div,
.gridfolder-box td>div {
    padding-top: 0.5rem !important;
}

.main-drive-spacebar>p {
    margin-bottom: 4px;
    margin-top: 8px;
    font-size: 12px;
    color: $color-primary;
}

.main-drive-spacebar .progress {
    width: 250px;
}

.upload-button {
    overflow: hidden;
    position: relative;
}

.upload-button form {
    visibility: hidden;
    position: absolute;
}

.upload-section {
    position: fixed;
    left: calc(100% - 420px);
    bottom: 10px;
    width: 400px;
    z-index: 1011;
}

.upload-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px !important;
    overflow: auto !important;
}

.upload-section ul li {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #eee;
}

.upload-section .col.filename {
    @include text-ellipsis;
}

.upload-section #progress-bar-per {
    float: right;
    position: relative;
}

.upload-section #progress-bar-per::after {
    content: "";
    position: absolute;
    left: 10%;
    top: 10%;
    background: $color-white;
    width: 80%;
    height: 80%;
    border-radius: 50%;
}

.upload-section #progress-bar-per svg {
    width: 40px;
}

.upload-section #progress-bar-per.inactive {
    animation: spinUploader 2s linear infinite;
}

.upload-section #progress-bar-per.inactive svg text {
    font-size: 0;
}

.upload-section #progress-bar-per.inactive svg path {
    stroke: $color-white;
}

.upload-section #progress-bar-per svg path {
    stroke: rgb(19, 126, 227);
}

.upload-section #count {
    position: absolute;
    color: $color-primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 100;
    display: block;
}

.upload-section #progress-bar-per.inactive #count,
.upload-section #progress-bar-per.file-uploaded #count {
    display: none;
}

.upload-section #progress-bar-per.file-uploaded svg path {
    stroke: #168d25;
}

.upload-section #progress-bar-per.file-uploaded::before {
    content: "\f00c";
    position: absolute;
    left: 50%;
    top: 50%;
    color: #168d25;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    z-index: 101;
    transform: translate(-50%, -50%);
}

@keyframes spinUploader {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.welcome-drive {
    border: dashed 1px #ddd;
    text-align: center;
    color: $color-secondary;
}

.welcome-drive .icon {
    font-size: 56px;
}

.welcome-drive .text {
    font-size: 20px;
}

.action-prompt {
    bottom: 20px;
    color: $color-white;
    left: 20px;
    z-index: 99;
    display: flex;
}

.action-prompt .text {
    @include text-ellipsis;
    max-width: 250px;
    width: 100%;
}

.move-folder-list .list-group-item:first-child {
    border-radius: 0;
    border-top: 0;
}

.copyto-folder-list .list-group-item:first-child {
    border-radius: 0;
    border-top: 0;
}

.move-folder-list .list-group-item:last-child {
    border-radius: 0;
}

.copyto-folder-list .list-group-item:last-child {
    border-radius: 0;
}

.drive-padding {
    padding-bottom: 0 !important;
}

.drive-padding .mb-5.table-sec {
    margin-bottom: 0;
}

.move-file-container {
    position: relative;
    padding-top: 35px !important;
}

.copyto-file-container {
    position: relative;
    padding-top: 35px !important;
}

.move-breadcrumb {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -35px;
    z-index: 2;
}

.copyto-breadcrumb {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -35px;
    z-index: 2;
}

.folder-item-text {
    @include text-ellipsis;
}

.minimise-progressbar,
.maximise-progressbar,
.close-progressbar {
    opacity: .5;
}


.grid-download .fa-download:hover {
    color: $color-primary !important;
}

.upload-section #progress-bar-per.upload-error #count {
    display: none;
}

.upload-section #progress-bar-per.upload-error::before {
    content: "\f01e";
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    left: 10%;
    top: 10%;
    background: $color-white;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    z-index: 100;
    line-height: 33px;
    color: $color-danger;
    opacity: 1;
}

.drive-object-detail-view {
    width: 300px;
    min-width: 300px;
    position: relative;
    margin-right: 0px;
    transition: all .3s;
}

.drive-object-detail-view.hide-file-details {
    margin-right: -300px;
}

.drive-object-detail-view .close-icon {
    font-size: 1.3em;
    font-weight: bold;
    border: 0px;
    background: transparent;
    color: $color-primary;
}

.object-drive-tab-details {
    min-width: 282px;
    max-width: 282px;
    position: relative;
    margin-right: 16px;
}

.drive-share {
    .remove-user-share {
        color: $color-secondary;
        &:hover {
            color: $color-black;
            cursor: pointer;
        }
    }
}
.delete-drive-version .swal-title {
    @include text-hidden;
}