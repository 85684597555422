.view-calendar {
    z-index: 1;

    .week-view-head {
        font-size: 75%;
        text-transform: uppercase;
        font-weight: 600;
    }

    .day-grid {
        .u {
            border-bottom: dotted 0.75px $color-gray-1;
            height: 20px;
        }

        .l {
            border-bottom: solid 0.75px $color-gray-1;
            height: 20px;
        }
    }

    .row .col {
        padding: 0;
        z-index: 0;
    }

    .all-day .col {
        min-height: 20px;
    }

    .time-sheet {
        max-width: 10px;
        margin-left: 70px;

        .p {
            border-bottom: solid 1px $color-gray-1;
            position: relative;
            height: 40px;
        }

        .c {
            font-size: 80%;
            position: absolute;
            right: 15px;
            top: -10px;
            white-space: nowrap;
        }
    }

    .dummy {
        max-width: 80px;
    }

    .view-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    h4 {
        width: 2.25rem;
        padding: 6px;
        margin: auto;
    }

    .view-scroll-all-day {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .row {
        margin: 0 !important;
    }


    .activity-container .bg-gray-100,
    .activity-container.bg-gray-100 {
        background-color: $color-blue-tint !important;
        background-image: repeating-linear-gradient(45deg, #eaeff4 0, #eaeff4 2px, transparent 0, transparent 50%);
        background-size: 10px 10px;
        background-color: $color-blue-tint;
    }

}

.calendar-left-panel {
    padding: 1rem 0.5rem;
    height: calc(100vh - 82px);
    overflow: hidden;
    padding-right: calc(0.5rem + 6px);

    &.shadow {
        box-shadow: 0 0.15rem 0.75rem 0 rgba($color-light, 0.15) !important;
    }

    &:hover {
        overflow: scroll;
        padding-right: calc(0.5rem);
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: $color-white;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-light-gray;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $color-secondary;
    }

    .staff-filter-menu .multiselect-container.dropdown-menu {
        max-width: fit-content;
    }
}

.cal-activity {
    color: $color-white;
    position: absolute !important;
}

.single-day-activity,
.month-cal-activity,
.meeting {
    background-color: $light-sky-blue-2;
    padding: 0 6px;
    color: $color-primary-hover;
    font-weight: 600;
    box-sizing: border-box;
    border: 1px solid $color-white;
    opacity: 1;
    text-align: left;
    cursor: grab;
    border-left: solid 2px $color-primary-hover !important;
    border-radius: 2px;
}

.single-day-activity:active,
.month-cal-activity:active {
    cursor: grabbing;
    background: $color-primary !important;
    color: $color-white !important;
}

/* For participates status (Busy/Free/Out Of Office) of activity QPC-2453*/
.status-busy {
    background-repeat: no-repeat;
    background-image: url(./../images/busy.jpg) !important;
    background-size: 7px 100%;
}

.status-out-of-office {
    background-repeat: no-repeat;
    background-image: url(./../images/out-office.jpg) !important;
    background-size: 7px 100%;
}

.status-busy-activity {
    background-repeat: no-repeat !important;
    background-image: url(./../images/busy.jpg) !important;
    background-size: 2px 100% !important;
    border-left: none !important;
}

.status-out-of-office-activity {
    background-repeat: repeat-y !important;
    background-image: url(./../images/out-office.jpg) !important;
    background-size: 4px auto !important;
    border-left: none !important;
}

.month-activity-container {
    position: relative;
}

.right-clicked-activity {
    background-color: $color-primary !important;
    transition: all 0.2s;
    color: $color-white !important;

    .activity-context-menu {
        color: $color-primary;
        background: $color-white;
    }
}

.allday-high-activity,
.allday-low-activity {
    position: absolute;
    right: 4px;
    font-size: 16px;
    line-height: 1;
    top: 1px;
}

.day-cal-activity.pending[data-priority="high"]::after,
.cal-activity.pending[data-priority="high"]::after,
.month-cal-activity.pending[data-priority="high"]::after {
    color: $color-white;
}

.activity-context-menu {
    width: 16px;
    height: 16px;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    background: $color-primary;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    color: $color-white;
    margin-right: 2px;
    position: relative;
    top: 0;
}

[data-uid="Calendar"] .dropdown-item:active .activity-context-menu {
    color: $color-primary;
    background: $color-white;
}

.month-cal-activity.middle-split-activity::before,
.month-cal-activity.last-split-activity::before {
    border-right-color: $light-sky-blue-2 !important;
}

.month-cal-activity.first-split-activity::after,
.month-cal-activity.middle-split-activity::after {
    border-left-color: $light-sky-blue-2 !important;
}

.month-cal-activity.middle-split-activity[data-activitytype="Call"]::before,
.month-cal-activity.last-split-activity[data-activitytype="Call"]::before {
    border-right-color: $color-status-in-progress-bg !important;
}

.month-cal-activity.first-split-activity[data-activitytype="Call"]::after,
.month-cal-activity.middle-split-activity[data-activitytype="Call"]::after {
    border-left-color: $color-status-in-progress-bg !important;
}

.meeting,
.call {
    box-shadow: none;
    cursor: default;
}

.other-user-single-day-activity {
    background-color: $color-light-blue;
    padding-left: 2px;
    box-shadow: 1px 1px 5px $color-gray-2;
}

.cal-activity.pending,
.month-cal-activity.pending {
    padding: 0px;
    padding-left: 0;
    position: relative;
}

.cal-activity.pending::after,
.month-cal-activity.pending::after {
    content: '';
    width: 2px;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: $color-primary;
}

.cal-activity.pending .activity-overflow,
.month-cal-activity.pending .activity-overflow {
    background-color: $color-white;
    min-height: 100%;
    color: rgba($color-primary, .8);
    padding-left: 6px;
    border: 1px solid rgba($color-primary, .8) !important;
    border-radius: 2px;
}

.cal-activity .activity-overflow,
.month-cal-activity .activity-overflow {
    line-height: 1.3;
}

.all-day-activity {
    background-color: $color-status-completed-bg;
    padding: 0 6px;
    color: $color-success ;
    font-weight: 600;
    border-left: solid 2px $color-success ;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid $color-white;
}

span.activity-overflow {
    @include text-hidden;
    display: block;
}

.first-split-activity::after,
.middle-split-activity::after {
    left: 100%;
    @include split-activity;
}

.first-split-activity.other-user::after,
.middle-split-activity.other-user::after {
    border-left-color: $color-status-design-review-bg !important;
}

.middle-split-activity::before,
.last-split-activity::before {
    right: 100%;
    @include split-activity;
}

.middle-split-activity.other-user::before,
.last-split-activity.other-user::before {
    border-right-color: $color-status-design-review-bg !important;
}

.middle-split-activity,
.last-split-activity,
.middle-split-activity.other-user,
.last-split-activity.other-user {
    border-left: transparent !important;
}

.msp-date-picker {
    width: 180px;
}

.other-user-all-day-activity {
    background-color: rgba($color-primary, .6);
    padding: 0px;
    padding-left: 10px;
    color: $color-white;
    border: solid 1px $color-gray-2;
}

.clicked-activity {
    background-color: $color-primary;
    border: solid 1px $color-gray-2;
    color: $color-white;
    z-index: 100 !important;
}

.activity-type-list-css,
[data-uid="Calendar"] .dropdown-item {
    padding: 0.25rem 1rem !important;
}

.wrap {
    @include text-hidden;
}

th.wrap {
    overflow: visible;
}

.other-user-month-cal-activity {
    color: $color-white;
    background-color: rgba($color-primary, .6);
    border: solid 1px $color-white;
}

.clicked-month-activity {
    background-color: $color-status-to-do-text;
    box-shadow: 1px 1px 5px $color-dark;
    width: 100% !important;
}

.activity-date-container {
    background-color: $color-gray-1;
}

.activity-container-row {
    min-height: 80px;

    .col {
        width: 14.28% !important;
    }
}

.other-user {
    --color: #3f51b5;
    background-color: $color-status-design-review-bg !important;
    padding: 0px 6px;
    /* padding-left: 10px; */
    color: var(--color) !important;
    border-left: solid 2px var(--color) !important;

    &[data-lockactivity="true"] {
        border-left: solid 2px $color-danger !important;
        cursor: no-drop;
        color: $color-danger !important;
    }
}

.lookup-item {

    &[data-status="rejected"],
    &[data-status="deleted"] {
        background-color: rgba($color-danger, .8);
        color: $color-white;
    }

    &[data-status="accepted"] {
        background-color: $color-primary;
        color: $color-white;
    }

    &[data-status="pending"] {
        background-color: $color-secondary;
        color: $color-white;
    }
}


.lookup-display-record {

    &[data-status="rejected"],
    &[data-status="deleted"] {
        color: $color-danger;
    }

    &[data-status="accepted"] {
        color: $color-primary;
    }

    &[data-status="pending"] {
        color: $color-secondary;
    }
}


/*Update By Nidhi Sharma, Desc: To add class of blocked-user*/
.deleted-record,
.object-detail .blocked-user .replaced-user {
    text-decoration: line-through !important;
}

.attendee-type {

    &[data-status="rejected"],
    &[data-status="deleted"] {
        color: $color-danger;
    }

    &[data-status="accepted"] {
        color: $color-primary;
    }

    &[data-status="pending"] {
        color: $color-secondary;
    }

    &.contact,
    &.user {
        position: relative;

        &::before {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            left: 0;
            color: $color-primary;
            transition: all 0.3s;
        }
    }
}


.msp-current-time {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 500;
    background-color: $color-danger;

    &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: $color-danger;
        content: "";
        display: block;
        border-radius: 50%;
        left: -6px;
        top: -3px;
    }
}

.users-calendar-sharing {
    .user-list-main {
        li.active {
            background-color: $color-success;
        }

        ul {
            list-style-type: none;
            margin: 0px;
            padding: 3px;
        }
    }

    .user-list-secondary {
        ul {
            list-style-type: none;
            margin: 0px;
            padding: 3px;
        }
    }
}


.activity-rightclick-menu {
    .dropdown-item {

        &.active,
        &:active {
            background: none !important;
            color: $color-primary !important;
            position: relative;
        }

        &.active {

            &[data-key="busy"]::after,
            &[data-key="free"]::after,
            &[data-key="lock"]::after,
            &[data-key="unlock"]::after {
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                right: 10px;
            }
        }
    }
}

/* today view */

.today-view {
    h3 {
        font-size: 1.125rem;
    }

    .bg-light {
        background: #F0F2FA;
    }

    h4 {
        font-size: 1rem;
    }

    .activity-type {
        font-size: 10px;
        margin-left: 10px;
        padding: 1px 8px;
        background: #EDF5FD;
        font-weight: 500;
        border-radius: 20px;
        color: #6AAEED;
        height: auto;

        &.call {
            background: #FDF3EB;
            color: #EDA467;
        }

        &.others {
            background: #EFFDF7;
            color: #68CCA1;
        }
    }

    .activity-bg,
    .pipeline-bg,
    .bind-tasks {
        padding: 10px;
    }

    .activity-list,
    .decisionpipeline-list,
    .pipeline-list {
        margin-bottom: 6px !important;
        padding: 10px;
    }
}


.c-user-list .c-user {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $color-primary;
    display: inline-block;
    margin-right: 4px;
    background-size: 100% !important;
    color: $color-white;
    line-height: 28px;
    text-align: center;

    &::after {
        display: none;
    }
}


.c-user-limit {
    line-height: 30px;
    text-align: center;
    color: #B5B8C4;
    @include text-hidden;
}

.dv-contact {
    min-width: 86px;
}

.dv-account {
    min-width: 130px;
}


/* For Activity */

.activity-list {
    &.dv-overdue {
        background: #FCF5F5 !important;

        h6 {
            a {
                color: #D06969 !important;
            }
        }

        .dates {
            color: #C79B9B !important;
        }
    }
}

.current-month-day {
    background-color: $color-primary;
    color: $color-white;
}

.context-menu {
    z-index: 900;
}

/*Start of Common Style for Day and Week*/
.slot-time:not(.time-slot-selected, .time-slot-selected-before-half, .time-slot-selected-after-half) .week-event-name {
    display: none;
}

.time-slot-selected.slot-time .week-event-name,
.time-slot-selected-before-half.slot-time .week-event-name {
    left: 0px;
}

.time-slot-selected-after-half.slot-time .week-event-name {
    left: 50%;
}

.week-hour-mark-wrapper .week-hour-marker:first-child:before {
    display: none;
}

.slot-body-row-wrapper:hover>div {
    background-color: $color-primary;
}

.slot-head-wrapper {
    min-height: 50px;
    max-height: 50px;

    .head-week-wrapper:last-child:after {
        background-color: $color-secondary;
    }
}

/*End of Common Style for Day and Week*/


// QPC-8071/yakshi, QPC-8495 - Neeraj
.calendar-nav {
    padding-left: 6px !important;
    font-weight: 600 !important;
    padding-top: 1px;
}

.calendar-navigator {
    font-weight: 600 !important;
    padding-top: 1px;
}

[data-uid="Calendar"] {
    margin-bottom: -3rem;
}

.img-profile-calendar {
    height: 50px;
}

.date-seek-prev,
.date-seek-next {
    line-height: 1;
    padding: 4px 5px;
    align-items: center;
    display: inline-flex !important;
}


@media (max-width:767px) {
    .calendar-left-panel {
        margin-left: -218px;
        position: fixed;
        z-index: 1;
        background: $color-white;
        transition: all .3s;
        overflow: auto !important;
        padding-right: 1rem !important;
        height: calc(100% - 82px) !important;

        &.active-left-mob {
            margin-left: 0;
            z-index: 998;
        }
    }

    .single-day-activity {
        z-index: unset !important;
    }

    .img-profile-calendar {
        height: 30px !important;
    }
}

/* Mobile version css Start */
@media (max-width:370px) {
    .label-calendar {
        font-size: 12px !important;
    }

}