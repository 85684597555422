$color_1: transparent;
$background-color_1: #eee;
$border-color_1: #eee;

@keyframes loading-skeleton {
	from {
		opacity: 0.4;
	}
	to {
		opacity: 1;
	}
}
.loading-skeleton {
	pointer-events: none;
	animation: loading-skeleton 1s infinite alternate;
	img {
		filter: grayscale(100) contrast(0%) brightness(1.8);
	}
	.tiles .result,
	.detailPageFields,
	.object-name,
	.grid-cell {
		color: $color_1 !important;
		appearance: none;
		-webkit-appearance: none;
		background-color: $background-color_1;
		border-color: $border-color_1;
	}

	a {
		color: $color_1 !important;
	}

	.grid-cell {
		height: 1rem !important;
		margin: 4px 8px;
	}
	.detailPageFields,
	.tiles .result {
		height: 1rem !important;
	}
}
