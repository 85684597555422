/* dashboard css start */

.grid-stack-item-content {
    background: $color-white;
    border: solid 1px $color-gray-1;
}

.grid-stack {
    padding: 0px;
    background-color: $color-white;
    background-size: calc(8.3333333333%) calc(8.3333333333%);
    background-image:
        linear-gradient(to right, $light-sky-blue-2 10px, transparent 1px),
        linear-gradient(to left, $light-sky-blue-2 10px, transparent 1px),
        linear-gradient(to top, $light-sky-blue-2 10px, transparent 1px),
        linear-gradient(to bottom, $light-sky-blue-2 10px, transparent 1px)
}

.dashboard-grid {
    padding: 20px;
    background-color: $light-sky-blue-2;
    border-radius: 4px;
}

.Compare-First {
    position: absolute;
    z-index: 1;
    padding: 15px;
}

.Compare-Second {
    position: absolute;
    z-index: 1;
    padding: 15px;
    top: 50%;
}

.Expand-Compare-First {
    position: absolute;
    z-index: 1;
    padding: 15px;
}

.Expand-Compare-Second {
    position: absolute;
    z-index: 1;
    padding: 15px;
    left: 50%;
}

.DashboardLoading {
    position: absolute;
    background: rgba($color-white, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9;

    img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
/* dashboard css end */