
.listview-container {
    .listview-left-panel {
      width: 230px;
      overflow: auto;
      transition: all 0.35s;
      height: calc(100vh - 80px);
    }
  
    &.listview-left-panel-hide .listview-left-panel {
      margin-left: -230px;
    }
  
    .listview-display {
      background-color: $color-gray-2 !important;
      width: calc(100% - 230px);
      height: calc(100vh - 82px);
      overflow: auto;
  
      .rounded-pill {
        font-size: 12px;
        font-weight: 500;
      }
  
      .table {
        thead th {
          border-bottom: 1px;
          border-top: 0 !important;
          padding: 0.35rem 0.5rem;
        }
  
        td {
          padding: 0.35rem 0.5rem;
  
          &.first {
            padding-left: 0;
          }
        }
  
        tr {
          &:hover,
          &.active {
            background-color: $light-sky-blue;
          }
  
          &.head-row:hover {
            background-color: $color-white;
          }
        }
      }
  
      .priority {
        &.high {
          color: $color-danger-hover;
        }
  
        &.medium {
          color: $color-warning-hover;
        }
  
        &.low {
          color: $color-gray-1;
        }
  
        &.no-priority {
          background-color: $color-no-priority-bg;
          color: $color-unassigned-text;
        }
      }
  
      .highlighter {
        &.priority {
          &.high {
            background-color: $color-priority-high-bg;
            color: $color-danger-hover;
          }
  
          &.medium {
            background-color: $color-priority-medium-bg;
            color: $color-priority-medium-text;
          }
  
          &.low {
            background-color: $color-priority-low-bg;
            color: $color-priority-low-text;
          }
        }
  
        &.status {
          &.to-do {
            background-color: $color-status-to-do-bg;
            color: $color-status-to-do-text;
          }
  
          &.in-progress {
            background-color: $color-status-in-progress-bg;
            color: $color-status-in-progress-text;
          }
  
          &.completed {
            background-color: $color-status-completed-bg;
            color: $color-status-completed-text;
          }
  
          &.backlog {
            background-color: $color-status-backlog-bg;
            color: $color-status-backlog-text;
          }
  
          &.in-design {
            background-color: $color-status-in-design-bg;
            color: $color-status-in-design-text;
          }
  
          &.design-review {
            background-color: $color-status-design-review-bg;
            color: $color-status-design-review-text;
          }
  
          &.in-development {
            background-color: $color-status-in-development-bg;
            color: $color-status-in-development-text;
          }
  
          &.development-completed {
            background-color: $color-status-development-completed-bg;
            color: $color-status-development-completed-text;
          }
  
          &.in-testing {
            background-color: $color-status-in-testing-bg;
            color: $color-status-in-testing-text;
          }
  
          &.ready-for-live {
            background-color: $color-status-ready-for-live-bg;
            color: $color-status-ready-for-live-text;
          }
  
          &.on-hold {
            background-color: $color-status-on-hold-bg;
            color: $color-status-on-hold-text;
          }
  
          &.no-status {
            background-color: $color-no-priority-bg;
            color: $color-unassigned-text;
          }
  
          &.overdue {
            background-color: $color-overdue-bg;
          }
        }
  
        &.duedate {
          &.today {
            background-color: $color-duedate-today-bg;
            color: $color-duedate-today-text;
          }
  
          &.past-due-date-overdue {
            background-color: $color-duedate-past-due-date-overdue-bg;
            color: $color-duedate-past-due-date-overdue-text;
          }
  
          &.future {
            background-color: $color-duedate-future-bg;
            color: $color-duedate-future-text;
          }
  
          &.no-due-date {
            background-color: $color-no-priority-bg;
            color: $color-unassigned-text;
          }
        }
  
        &.assignedto,
        &.tag {
          &,
          .lookup-display-record {
            background-color: $color-assignedto-bg;
            color: $color-assignedto-text;
          }
        }
  
        &.assignedto.unassigned {
          background-color: $color-no-priority-bg;
          color: $color-unassigned-text;
        }
  
        &.tag.no-tags {
          background-color: $color-no-priority-bg;
          color: $color-unassigned-text;
        }
      }
    }
  
    .listview-buttons .btn-outline-secondary {
      background-color: $color-white;
  
      &:hover {
        background-color: $color-secondary;
      }
    }
  
    .minimize-listview-left-panel {
      background-color: $color-gray-2;
      color: $color-secondary;
      width: 2rem;
      height: 2rem;
      padding: 0;
      font-size: 16px;
    }
  
    .maximise-listview-left-panel {
      background-color: $color-white;
      margin-right: 0.5rem;
      display: none;
  
      i {
        transform: rotate(180deg);
      }
    }
  
    &.listview-left-panel-hide .maximise-listview-left-panel {
      display: block;
    }
  
    .listview-left-panel {
      .filter-listing {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0.875rem;
  
        li {
          display: block;
          padding: 4px 1rem;
          position: relative;
          @include text-hidden;
  
          .task-act-btn {
            position: absolute;
            right: 12px;
            top: 2px;
            display: none;
            background: $color-white;
  
            &.show {
              display: block;
            }
          }
  
          &:hover {
            color: $color-primary !important;
          }
        }
  
        > li:hover,
        ul > li:hover,
        ul > li:hover .nested li:hover {
          color: $color-primary !important;
        }
  
        ul > li:hover li,
        > li:hover .nested li {
          color: $color-black;
        }
  
        .group-list-caret {
          cursor: pointer;
          user-select: none;
          display: block;
          position: relative;
  
          .caret-icon {
            color: $color-secondary;
            position: absolute;
            margin-top: 4px;
            left: -13px;
            opacity: 0.8;
  
            &:hover {
              opacity: 1;
            }
          }
  
          &.group-caret-down .caret-icon {
            transform: rotate(90deg);
          }
        }
  
        .nested {
          display: none;
          padding-left: 0;
        }
  
        .active {
          display: block;
  
          &,
          li.active {
            background-color: $light-sky-blue;
          }
        }
  
        .text-found {
          background-color: rgba($color-success-hover, 0.16);
        }
      }
    }
  }
  .group-by-drop {
    .dropdown-menu {
      padding: 0 !important;
  
      .dropdown-item {
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
  
        i {
          width: 16px;
          text-align: center;
          font-size: 12px;
          color: $color-secondary;
        }
  
        .default {
          color: $color-secondary;
          font-size: 11px;
        }
  
        .pin {
          position: absolute;
          right: 4px;
          top: 4px;
          display: none;
        }
  
        &:hover,
        &.active,
        &:active {
          background: unset;
  
          .pin {
            display: block;
          }
  
          i {
            color: $color-primary;
          }
        }
  
        &:hover,
        &.active {
          color: $color-primary;
        }
      }
    }
  
    .dropdown-toggle::after {
      display: none;
    }
  }
  
  .groupby-list-view {
    position: relative;
    min-height: 30px;
  }
  
  .groupby-table {
    thead {
      .th {
        max-width: 200px;
      }
  
      position: unset;
    }
  
    .main-col {
      min-width: 300px;
    }
  }
  
  .groupby-colpase-title {
    position: absolute;
    left: 0;
    top: 5px;
    z-index: 10;
    display: flex;
    align-items: center;
  
    .btn {
      height: 26px;
      padding: 3px 10px;
      border-radius: 2px;
      font-weight: 500;
      outline: none;
      box-shadow: none;
      line-height: 1.3;
  
      &[aria-expanded="false"] i {
        transform: rotate(-90deg);
      }
  
      &[aria-expanded="true"] i {
        transform: rotate(0deg);
      }
  
      i {
        color: $color-secondary;
        transition: all 0.2s;
      }
    }
  
    .groupby-count {
      color: $color-secondary;
      font-size: 12px;
      margin-left: 0.25rem;
    }
  }
  
  .priority-dropdown {
    i::after {
      display: none;
    }
  
    .dropdown-menu {
      min-width: 105px !important;
  
      .dropdown-item {
        padding: 0.25rem 1rem;
      }
    }
  }
  
  .groupby-list-action {
    .list-action-btn {
      opacity: 0;
      padding-left: 0.5rem;
  
      .btn {
        padding: 0.1rem 0.25rem;
      }
    }
  
    &:hover .list-action-btn {
      opacity: 1;
    }
  }
  
  .task-quick-view {
    width: 300px;
    min-width: 300px;
    transition: all 0.3s;
    margin-right: -300px;
    height: 100vh;
    overflow: auto;

    &.quick-show {
        margin-right: 0;
    }
}

.task-quick-view-header {
    display: flex;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $color-light-gray;
}

.task-icon {
    width: 27px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
}

.tasks-sublist-menu .dropdown-item {
    padding: 0.1rem 1.5rem 0.1rem 2rem;
    @include text-hidden;
}

.add-quick-task {
    padding: 0.5rem 1rem;
}

.no-quick-task img {
    width: 100%;
    padding: 0.5rem 1rem;
}

.expand-addtask {
    position: absolute;
    bottom: 0;
    left: calc(50% - 17px);
}

.task-lists {
    .todo-edit {
        max-width: 45px;

        .task-edit-icon {
            display: none;
        }
    }

    .todo-check {
        max-width: 30px;
        position: relative;
        input {
          margin-top: 3px;
        }
    }
}

.task-view:hover .todo-edit .task-edit-icon {
  display: block;
}

.task-view:hover .todo-edit .task-pri {
  display: none;
}
.task-list-container {
  .task-action-icon {
    display: none;
  }
  .task-list-item:hover .task-action-icon {
    z-index: 10;
    display: unset;
  }
}
.task-word-wrap {
  @include text-hidden;
  word-break: break-word;
  width: auto;
}

.new-task-round {
  background: $color-primary-hover;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  /* vertical-align: middle; */
  margin: 6px 6px 0 6px;
}

.tasks-list-overdue {
  background-color: #FCF5F5 !important;
}

.today-view .tasks-list {
  margin-bottom: 6px !important;
  padding: 10px;
}