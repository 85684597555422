/*========================Loader Scss==============================*/

#main-loading {
    position: fixed;
    background: rgba($color-white, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    display: none;

    &.active {
        display: block;
    }

    img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loading {
    position: absolute;
    background: rgba($color-white, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1010;

    &.active {
        display: block;
    }

    img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loading-container {
    position: relative;
}
