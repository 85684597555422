/*survey start */
.svc-page__question-type-selector-icon {
    line-height: normal;
}

.sv-list__filter-icon {
    line-height: normal;
}

.sv-components-row label {
    margin-bottom: auto;
}

.spg-drag-element__svg {
    height: 40px !important;
    width: 40px !important;
}

.spg-search-editor_container {
    height: 56px !important;
    width: 100% !important;
}

.survey-url {
    word-break: break-all;
}

// for theme
#deviceSelector.sv-action {
    display: none;
}

#orientationSelector.sv-action {
    display: none;
}

#showInvisible.sv-action {
    display: none;
}

#svc-theme-import.sv-action {
    display: none;
}

#svc-theme-export.sv-action {
    display: none;
}

#svc-reset-theme.sv-action {
    display: none;
}

.spg-color-editor__color-swatch {
    margin-top: auto;
}

.svc-string-editor,
.sv-string-viewer {
    white-space: normal;
}

.sd-boolean__thumb-text .sv-string-editor,
.sd-boolean__thumb-text .sv-string-viewer {
    white-space: nowrap;
}

.survey-chart-continer {
    position: relative;
}

.survey-chart {
    width: 75%;
    height: 100%;
    position: absolute;
}

.sd-row--multiple .svc-question__content--fullname .svc-row .sd-row.sd-row--multiple {
    flex-wrap: wrap;
}

.shared-folder {
    position: relative;
}

.svc-page__question-type-selector-icon {
    line-height: normal;
}

.sv-list__filter-icon {
    line-height: normal;
}

.sv-components-row label {
    margin-bottom: auto;
}

/*survey end */