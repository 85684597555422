.datafield-icon {
    padding-left: 20px;
    position: relative;

    &::before {
        position: absolute;
        left: 6px;
        top: 0px;
        content: "\f0c8";
        font-weight: bold;
        font-family: "Font Awesome 5 Free";
        opacity: .6;
    }

    &.autoincrement::before {
        content: "\f3bf" !important;
    }

    &.time::before {
        content: "\f017" !important;
    }

    &.date::before {
        content: "\f073" !important;
    }

    &.database::before {
        content: "\f1c0" !important;
    }

    &.email::before {
        content: "\f0e0" !important;
    }

    &.dropdown::before {
        content: "\f150" !important;
    }

    &.multiselect::before {
        content: "\f0ae" !important;
    }

    &.url::before {
        content: "\f0ac" !important;
    }

    &.phone::before {
        content: "\f3cd" !important;
    }

    &.text::before {
        content: "\f15c" !important;
    }

    &.number::before {
        content: "\f1ec" !important;
    }

    &.int::before {
        content: "\f129" !important;
    }

    &.decimal::before {
        content: "\f525" !important;
    }

    &.currency::before {
        content: "\f155" !important;
    }

    &.eformula::before {
        content: "\f698" !important;
    }

    &.formula::before {
        content: "\f53e" !important;
    }

    &.checkbox::before {
        content: "\f14a" !important;
    }

    &.datetime::before {
        content: "\f073" !important;
    }

    &.textarea::before {
        content: "\f036" !important;
    }

    &.lookup::before {
        content: "\f002" !important;
    }

    &.multilookup::before {
        content: "\f00e" !important;
    }

    &.image::before {
        content: "\f03e" !important;
    }

    &.file::before {
        content: "\f1c6" !important;
    }

    &.location::before {
        content: "\f689" !important;
    }

    &.collection::before {
        content: "\f0ce" !important;
    }

    &.objectid::before {
        content: "\f246" !important;
    }
}
