@mixin control-box {
    color: $color-primary;
    cursor: pointer;
    min-width: 210px;
    max-width: 340px;
}

@mixin control-box-card {
    background: $color-primary;
    color: $color-white;
    border-color: $color-primary;
}

.control-panel,
.integration-plugins {
    .txtSearch {
        max-width: 600px;
    }

    .box {
        @include control-box;

        .card {
            transition: all 0.3s;
            h4 {
                color: $color-secondary;
            }
        }

        &:hover .card {
            @include control-box-card;
            h4 {
                color: $color-white;
            }
        }
    }
}

.operaIcon {
    line-height: 52px;

    &::before {
        content: url(../images/opera.png);
    }
}

.link-page:hover .operaIcon::before {
    content: url(../images/opera-w.png);
}

.email-client {
    clear: both;
    margin-top: 20px;
}

.client-name,
.method-name {
    width: 150px;
    height: 150px;
    text-align: center;
    border: 2px solid $color-gray-1;
    border-radius: 0.35rem;
    margin-right: 15px;
    margin-bottom: 15px;
    float: left;
    background: $color-white;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;

    &.active::after {
        content: "\f00c";
        width: 30px;
        height: 30px;
        background: $color-primary;
        color: $color-white;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 0.3rem 0 0.2rem 0;
        font-family: "Font Awesome 5 Free";
        font-weight: bold;
        line-height: 30px;
    }

    img {
        height: 75px;
        margin-top: 20px;

        &.zoho {
            max-width: 120px;
            height: auto;
            margin-bottom: 34px;
        }

        &.custom {
            fill: $color-primary;
        }
    }

    &.zoho span {
        line-height: 75px;
        display: block;
        margin-top: 20px;
    }
}

.userObjectDetails {
    padding: 3px;
    margin: 5px;
}

.removeUserInvite {
    color: $color-danger;
}

.pendingUser {
    border: 2px solid $color-secondary-hover;
}

.rejectedUser {
    border: 2px solid $color-danger;
}

.acceptedUser {
    border: 2px solid $color-success;
}

.custom-user-invites .btn-outline-secondary {
    padding-right: 0;
    position: relative;
    margin: 0;
    width: calc(100% - 0.65rem);
    text-align: left;
    padding: 0;
    border: 0 !important;

    &:hover {
        background: $color-white;
        color: $color-secondary;
    }

    .fa-times {
        position: absolute;
        top: 2px;
        right: 0;
    }
}

.filter-row .custom-user-invites .btn-outline-secondary {
    padding: 0;
    border: 0 !important;
    text-align: left;
    margin: 0;
}
// For Control Panel Small-View

.control-panel {
    .small-view {
        .col-sm-3 {
            &.pb-4 {
                padding-bottom: 1rem !important;
            }

            &.box {
                max-width: 100%;
            }

        }

        .card-body {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 0.5rem;
            padding: 1rem;

            h4.mb-4 {
                margin-bottom: 0 !important;
                padding-left: 0rem;
                font-size: 0.938rem;
            }

            .fa-4x {
                font-size: 1em !important;
                background-color: $color-primary;
                color: $color-white;
                border-radius: .25rem;
                height: 1.5rem;
                width: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $color-white;
            }

            .mt-4 {
                margin-top: 0rem !important;
            }

            .mb-4 {
                margin-bottom: 0rem !important;
            }
        }
    }

    .search-control-ele {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .control-search {
        max-width: 300px;
        width: 100%;
    }

    .control-view-dropdown {
        max-width: 160px;
        width: 100%;
    }

    .input-container {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        gap: .5rem;
    }
}

// **** End**** QPC-8633/faizan