@import '../base/bootstrap';

.login-layout{
	.h-100vh {
		min-height: 100vh;
	  }
	  
	  .login-content-container {
		max-width: 500px;
		margin: 0 auto;
		padding: 0 15px;
	  
		&.login-content {
		  max-width: 800px !important;
		}
	  }
	  
	  .map-bg {
		background: url(../../images/map-bg.png) no-repeat;
		background-size: 90%;
	  }
	  
	  .login-text-sec {
		h1 {
		  font-size: 2.25rem;
		  font-weight: 500 !important;
		  max-width: 650px;
		}
	  
		p {
		  font-size: 1rem;
		  max-width: 600px;
		}
	  
		.btn.btn-warning {
		  background-color: $color-warning;
		  color: $color-white;
		  font-weight: 500;
		  border-color:$color-warning;
		  font-size: 1rem;
	  
		  &:hover {
			background-color: $color-warning-hover;
			border-color: $color-warning-hover;
		  }
		}
	  }
	  
	  .ellipse-1 {
		position: absolute;
		top: -20px;
		left: -80px;
		max-width: 224px;
		z-index: 0;
	  
		img {
		  width: 100%;
		}
	  }
	  
	  .ellipse-2 {
		position: absolute;
		bottom: -40px;
		right: -40px;
		max-width: 95px;
		z-index: 0;
	  
		img {
		  width: 100%;
		}
	  }
	  
	  .top-img {
		z-index: 1;
		position: relative;
	  }
	  
	  .text-dark {
		color: $color-black !important;
	  }
	  
	  .form-group {
		margin-bottom: 1.25rem;
	  
		label {
		  color:$color-black;
		  margin-bottom: 0.5rem;
		  font-size: 14px;
		}
	  }
	  
	  .form-control {
		border-color: $color-form-border;
		border-radius: 0.675rem;
		height: 52px;
		line-height: 1;
	  }
	  
	  .btn-login {
		height: 52px;
		line-height: 1;
		border-radius: 0.675rem;
		font-size: 1.25rem;
		box-sizing: border-box;
	  }
	  
	  .custom-checkbox .custom-control-label::before {
		border-radius: 0.25rem;
	  }
	  
	  .overflow-x-hidden {
		overflow-x: hidden !important;
	  }
	  
	  @media (max-width: 767px) {
		.h-100vh {
		  height: unset;
		}
	  
		.form-control,
		.btn-login {
		  height: 40px;
		  border-radius: 0.5rem;
		}
	  
		.btn-login {
		  font-size: 1rem;
		}
	  }
	  
}