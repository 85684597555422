/* added by Neeraj for some Quill Text Editor CSS Changes for story QPC 7463*/
.ql-container {
    height: 150px;
    border-radius: 0 0 0.2rem 0.2rem !important;
}

.ql-toolbar.ql-snow {
    background-color: $color-blue-tint;
    border-radius: 0.2rem 0.2rem 0 0;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border: 1px solid $color-gray-1;
}

.ql-toolbar.ql-snow .ql-picker-label {
    overflow: hidden;
}

.ql-snow.ql-toolbar button {
    text-align: center;
    width: 30px;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;
}

.ql-tooltip {
    left: 0 !important;
}

.ql-snow .ql-picker {
    z-index: 1000;
}

.ql-snow .ql-tooltip a.ql-action::after {
    margin-left: 0px;
    color: $color-white;
    border-right: 0px;
    padding-right: 0px;
}

.ql-snow .ql-tooltip a.ql-remove::before {
    margin-left: 0px;
}

.ql-snow .ql-tooltip a {
    line-height: 1.415;
}

.ql-save {
    background-color: $color-primary;
    color: $color-white;
    margin-left: 10px;
    border-radius: 0.35rem;
}

.ql-remove {
    margin-left: 5px;
    background: $color-gray-1;
    border-color: $color-gray-1;
    border-radius: 0.35rem;
}

.editor-container {
    z-index: 1;
    position: relative;
}

.ql-comment-section {
    z-index: 0;
    max-width: calc(100% - 45px);
}

.ql-edit-comment {
    max-width: 45px;
}

.ql-snow .ql-tooltip a.ql-preview {
    vertical-align: middle;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    max-height: 180px;
    overflow: auto;
}

.editor-text p {
    margin-bottom: 0;
}

/*QPC-8650 CSS Quill Editor added by Abrar */
.display-message {
    border: 0 !important;
    height: unset;

}

.display-message .ql-editor {
    padding: 0;
    padding-left: 0;
}

.display-message .ql-editor ol {
    padding-left: 0;
}

/* End QPC-8650*/