/*===================Reports=======================*/

/* Report Editor Padding Issue resolved Start */
.custom-control-label{
    &.auto-refresh-button {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
        padding: 5px 0 0 10px;
    }
}

/* Report Editor Padding Issue resolved End */
.reports {
    .calendar-left-panel {
        width: 315px;
        max-width: 315px;
        min-width: 315px;
    }
    .report-list .search-in-list {
        max-width: 200px !important;
    }
}

[data-uid*="ReportEditor"] .reports .calendar-left-panel {
    max-height: calc(100vh - 145px) !important;
}

.report-folder {
    list-style: none;
    padding: 0;

    li {
        position: relative;
        z-index: inherit;
        margin-bottom: 1px;
        &.selected {
            background-color: $color-light-gray;
        }
        &::first-child .btn-pin-top {
            display: none;
        }
        .fa-thumbtack, .btn-group {
            position: absolute;
            padding: 5px;
        }
        .gear-ctrl {
            position: relative;
            padding: 0;
            margin-right: 20px;
        }
        .fa-thumbtack {
            top: 0;
            right: 15px;
            z-index: auto;
        }
        .btn-group {
            right: 0;
            top: 0;
            z-index: auto;
            padding: 0;
            .btn {
                padding: 4px;
            }
        }
        &.active .btn {
            color: $color-white;
        }
        .btn {
            visibility: hidden;
        }
        &:hover .btn {
            visibility: visible;
            color: $color-light;
        }
        
    }

    &>li.custom>a::before {
        content: "\f4ff";
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        font-size: 5px;
        color: $color-white;
    }

    &>li{

        a {
            padding: 0.35rem 1rem 0.35rem 0.5rem;
            border-radius: 0.2rem;
            color: $color-dark;
            text-decoration: none;
            transition: all 0.2s;
            position: relative;
            display: block;
            padding-right: 30px;
            user-select: none;
            @include text-hidden;
        }
        &.active>a {
            color: $color-white !important;
            background: $color-primary;
        }
        &:hover>a {
            color: $color-light !important;
            background-color: $color-gray-1;
        }
        &>ul {
            padding-left: 15px;
            list-style: none;
        }

    }



}

.folder-owner {
    margin-left: 0px;
}

.report-list {
    width: calc(100% - 315px);
}

.report-format {
    width: 140px;
}

.report-show {
    width: 100px;
}

.text-over-ellipse {
    @include text-hidden;
}

.rename-report .btnSave {
    display: flex;
    justify-content: center;
    padding: 6px;

    i {
        font-size: 14px;
    }
}


#report-group .btn-light {
    background: $color-blue-tint;
    border-color: $color-gray-1;
}

#report-filter .msp-popup {
    margin-left: -29px;
}

.btn-refresh-report,
.text-refresh-report {
    display: none;
}

.report-structure-changed .btn-refresh-report,
.report-structure-changed .text-refresh-report {
    display: inline;
}

.report-structure-changed .report-preview-table {
    background-color: $color-gray-2;
}


.static-header {
    position: fixed;
    top: 0px;
    background: $color-white;
    z-index: 1;
}

.sticky-header {
    position: sticky;
    top: 0px;
    z-index: 1;
}

thead.sticky-header{
    &::before {
        content: "";
        top: 0px;
        position: absolute;
        border-top: 2px solid $color-gray-1;
        width: 100%;
    }
    &::after {
        content: "";
        bottom: 0px;
        position: absolute;
        border-top: 2px solid $color-gray-1;
        width: 100%;
    }
}

.formula-field-list {
    overflow: auto;
    @include text-hidden;
    height: 240px;
}

.CodeMirror {
    border: 1px solid $color-secondary;
}

.CodeMirror-hints,
.CodeMirror-hint,
.CodeMirror-hint-active {
    z-index: 10000 !important;
}

.campaign-report-content svg>g>g:last-child {
    pointer-events: none;
}
.help-document-detail .report-folder>li a {
    white-space: normal;
}

.date-ctrl-report {
    max-width: 100% !important;
}

.dash-report-chart {
    width: 100%;
    height: calc(100% - 43px);
    position: absolute;
}

.dash-report-chart1 {
    width: 100%;
    height: calc(50% - 22px);
    position: absolute;
}

.dash-report-chart2 {
    width: 100%;
    top: 50%;
    height: calc(50% - 22px);
    position: absolute;
}

.expand-dash-report-chart1 {
    width: 50%;
    position: absolute;
}

.expand-dash-report-chart2 {
    width: 50%;
    left: 50%;
    position: absolute;
}

.GoToReport:hover {
    text-decoration: underline;
    cursor: pointer;
}

// added by komal QPC_6626 for background of selected element of move report Pop-up.
.folder-selected {
    // 
    background: $light-sky-blue-2 !important;
    color: $color-info-hover !important;
}

.chart-list {
    overflow: hidden;
    width: 270px;
    position: absolute;
    display: none;
    right: 10px;
    z-index: 9;
    background-color: $color-white;
    border: 1px solid $color-light-gray;
    top: 45px;
    padding: 5px;
    text-align: left;
}

ul.chart-sub-lst {
    margin: 0px;
    padding: 0px;
    text-align: left;
    li {
        margin: 0px;
        padding: 2px 8px;
        text-align: center;
        display: inline-grid;
        width: 58px;
        a {
            color: $color-black;
            filter: grayscale(100%);
            &:hover {
                color: $color-primary;
                text-decoration: none;
                filter: grayscale(0%);
            }
            &.active {
                filter: grayscale(0%);
                color: $color-primary;
                border-bottom: solid 2px $color-primary;
                display: block;
                padding: 1px;
            }
        }

        span {
            display: block;
            padding-top: 0px;
            font-size: 12px;
            font-weight: bold;
        }
        img {
            width: 24px;
        }
    }
}

@media (max-width:767px){
    .reports .calendar-left-panel {
        margin-left: -318px !important;
    }
}
/*===================Reports END=======================*/
