.diary-container {
    background: $color-gray-2;
    height: calc(100vh - 82px);
    overflow: auto;

    .cal-tabs-sec {
        max-width: 100% !important;
        width: 100% !important;
        justify-content: space-between;
        display: flex;
        align-items: center;
    }

    .w-250 {
        max-width: 250px;
        min-width: 250px;
    }

    .z-index-1 {
        z-index: 1;
    }

    .slots-row {
        &:first-child {
            .slot-time {
                border-bottom: 2px solid $color-gray-1;
                // border-right: 1px solid $color-gray-1;
                height: 50px;

                .before-half-marker,
                .after-half-marker {
                    &:hover {
                        background: transparent;
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 100%;
                    left: calc(50% - 0.031rem);
                    top: 0px;
                    background: rgb(227, 230, 240);
                }

                &:first-child::before,
                &:last-child::before {
                    display: none;
                }
            }
        }

        .slot-time {
            min-width: 76px;
            font-size: 12px;
            border-bottom: 1px solid $color-gray-1;

            .before-half-marker,
            .after-half-marker {
                &:hover {
                    background: $color-primary;
                }
            }

            &:last-child {
                display: none !important;
            }
        }

        &:hover {
            background: none !important;
        }

        &.active {
            .slots-col-wrapper {
                background: $color-overdue-bg;
            }

        }
    }

    .slot-body-row-wrapper:hover>div {
        background: none;
    }

    .accordian-room-heading {
        border-bottom: 2px solid $color-gray-1;
    }

    #room-calendar-side-accordion .card {
        border-left: 0;
        border-right: 0;
    }

    #room-calendar-side-accordion .card-header {
        background: transparent !important;

        h5 {
            font-weight: 600;
            color: #47555F;

            &.active {
                color: $color-primary;
            }
        }

        &.active {
            background-color: $color-overdue-bg !important;
        }
    }

    .room-option {
        color: #47555F;
    }

    .slot-date {
        background-color: #00A1DF !important;
        border-radius: 3px;
        top: 2px;
        z-index: 10 !important;
        line-height: 1.35;
        letter-spacing: .01rem;
    }

    .time-span {
        bottom: 7px !important;
    }

    .slots-head-wrapper .slots-col-wrapper {
        border-bottom: 0 !important;
    }

    .slot-body-row-wrapper .slots-col-wrapper:after {
        display: none;
    }

    .date-wrapper .slot-date.tommorow-slot-date {
        right: -800px;
        left: unset;
    }

    .calendar-dropdown {
        max-width: 152px;
        background: $color-white;
        border-radius: .25rem;
        display: inline-flex;
        align-items: center;

        .date-seek {
            color: #4585BE !important;
        }

        .date-seek:hover {
            background-color: transparent !important;
            color: $color-primary !important;
        }

        .btn-outline-primary,
        .form-control {
            border-color: white !important;
        }



        .input-group-sm>.form-control:not(textarea) {
            padding: 0.2rem 0.5rem 0.2rem 0;
            font-size: .8rem;
            height: auto !important;

        }

        .btn.btn-outline-primary.btn-sm {
            padding-left: 0;

            &:hover {
                background: transparent;
                color: $color-primary;
            }
        }

        .msp-popup {
            left: -23px !important;
        }

        .form-control.form-control-sm {
            padding-right: 0 !important;
        }

    }

    .slots-wrapper.week-time-slot .head-week-wrapper {
        background-color: $color-white;

        &:after {
            background: $color-gray-1 !important;
        }

        &:before {
            position: absolute;
            content: '';
            right: 0px;
            height: 1px;
            background-color: $color-gray-1;
            left: 0px;
            bottom: 0px;
        }

        h6 {
            color: $color-primary !important;
            font-weight: 700;
        }

        .promp-header {
            background-color: #00A1DF !important;
        }
    }

    .week-table-header {
        height: 50px;
    }

    .setup-teardown-color {
        border: 0px;
        background-image: repeating-linear-gradient(45deg, #eaeff4 0, #eaeff4 2px, transparent 0, transparent 50%);
        background-size: 10px 10px;
        background-color: $color-blue-tint !important;
    }
}
/* Diary - VenueBooking Start */
.week-venue-booking {
    min-height: 45px;
    max-height: 45px;
    left: var(--week-left);
    background-color: var(--week-bg-color);
    color: var(--week-color);
    font-weight: 600;
    position: absolute;
    z-index: 5;
    min-width: var(--week-width);
    max-width: var(--week-width);
    @include text-hidden;
    padding-top: 12px;
    padding-left: 5px;
    cursor: grab;
    border: 1px solid $color-white;
}

.venue-booking-next-date::after {
    content: "\f054";
    right: 0;
    position: absolute;
    font-family: "Font Awesome 5 Free";
}

.day-venue-booking {
    min-height: 45px;
    max-height: 45px;
    left: var(--week-left);
    background-color: var(--week-bg-color);
    color: var(--week-color);
    font-weight: 600;
    position: absolute;
    z-index: 8;
    min-width: var(--week-width);
    max-width: var(--week-width);
    @include text-hidden;
    padding-top: 15px;
    padding-left: 6px;
    cursor: grab;
    border-left: 1px solid $color-white;
    border-right: 1px solid $color-white;
}

/* Diary - VenueBooking Start */

/* Diary Calendar CSS Start end  */

.cursor-pointer:hover {
    cursor: pointer;
}

.date-seek:hover {
    cursor: pointer;
    background-color: $color-gray-2;
    /* color: $color-white; */
}

.select-sync-button {
    color: $color-white;
    cursor: pointer;
}

.diary-calendar-border {
    border-radius: 0%;
    padding: 0px !important;
}

.diary-calendar-background-today-date {
    background-color: $color-primary;
    border-radius: 50%;
    height: 100%;
    padding: 6px 0px;
}

.diary-calendar-background-selected-date {
    background-color: $color-secondary;
    border-radius: 50%;
    height: 100%;
    padding: 6px 0px;
}

/*Start of Style for Day at Diary Component*/
#room-calendar-side-accordion>.card .card-header:first-child,
#room-calendar-side-accordion>.card:first-of-type,
#room-calendar-side-accordion>.card:last-of-type {
    border-radius: 0px;
}

.slot-time {
    min-width: 42px;
    font-size: 11px;
}

.slot-date {
    font-size: 10px;
}

.time-span {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
}

.slots-head-wrapper {
    .time-span {
        // color: $color-white;
        color: $color-primary;
        font-weight: 700 !important;
    }
    .slot-date {
        font-weight: bold;
    }
    .slots-col-wrapper {
        // background-color: var(--day-bg-head-color);
        min-height: 50px;
        max-height: 50px;
        border-bottom: 1px solid $color-white;
        overflow: hidden;
    }
    .slot-time{
        .before-half-marker, .after-half-marker {
            height: 50px;
        }
    }
   
}



.date-wrapper {
    .slot-date {
        position: absolute;
        top: 2px;
        z-index: 5;
        &.today-slot-date {
            left: 30px;
        }
        &.tommorow-slot-date {
            left: 996px;
        }
    }
}


.day-event-name {
    position: absolute;
    z-index: 6;
    opacity: 0.6;
    bottom: 0px;
    font-size: 11px;
    background-color: var(--bg-color);
    width: calc(var(--event-size) * 42px);
}

.slot-time:not(.time-slot-selected, .time-slot-selected-before-half, .time-slot-selected-after-half) .day-event-name {
    display: none;
}

.time-slot-selected.slot-time .day-event-name,
.time-slot-selected-before-half.slot-time .day-event-name {
    left: 1px;
}

.time-slot-selected-after-half.slot-time .day-event-name {
    left: calc(50% + 1px);
}

.slot-time .before-half-marker {
    position: absolute;
    bottom: 0px;
    left: 1px;
    width: 38px;
    height: 46px;
    z-index: 5;
}

.slot-time .after-half-marker {
    position: absolute;
    bottom: 0px;
    right: -1px;
    width: 38px;
    height: 46px;
    // z-index: 5;
}

.slot-time .after-half-marker:after,
.slot-time .before-half-marker:after,
.slot-time .after-half-marker:before,
.slot-time .before-half-marker:before {
    content: '';
    width: 1px;
    position: absolute;
    z-index: 6;
    bottom: 0px;
    display: inline-block;
    background-color: $color-white;
}

.slot-time .before-half-marker:before {
    height: 4px;
    left: 0px;
    display: none;
}

.slot-time .before-half-marker:after {
    height: 6px;
    right: 0px;
    display: none;
}

.slot-time .after-half-marker:before {
    left: 0px;
    height: 6px;
    display: none;
}

.slot-time .after-half-marker:after {
    right: 0px;
    height: 4px;
    display: none;
}

.slot-body-row-wrapper {
    min-height: 46px;
    max-height: 46px;
  
    .slots-col-wrapper {
      min-height: 46px;
      max-height: 46px;
      position: relative;
  
      &:after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: $color-secondary;
      }
    }
  
    .slot-time {
      .after-half-marker:after,
      .before-half-marker:after,
      .after-half-marker:before,
      .before-half-marker:before {
        background-color: $color-secondary;
      }
    }
  
    .time-slot-selected-before-half.slot-time .before-half-marker,
    .time-slot-selected-after-half.slot-time .after-half-marker,
    .time-slot-selected.slot-time .after-half-marker,
    .time-slot-selected.slot-time .before-half-marker {
      background-color: var(--time-slot-bg-color);
      opacity: 0.6;
    }
  
    .overlap-before.slot-time .before-half-marker {
      background-color: var(--time-slot-bg-overlap-color);
      opacity: 0.6;
    }
  
    .overlap-after.slot-time .after-half-marker {
      background-color: var(--time-slot-bg-overlap-color);
      opacity: 0.6;
    }
  
    &:hover {
      .slots-col-wrapper {
        background-color: $color-primary;
      }
  
      .slot-time {
        .after-half-marker:after,
        .before-half-marker:after,
        .after-half-marker:before,
        .before-half-marker:before {
          background-color: $color-white;
          border-color: $color-white;
        }
      }
    }
  }
  

.no-time-on-row {
    &.slot-body-row-wrapper {
      .slot-date {
        display: none;
      }
  
      .time-span {
        display: none;
      }
    }
  }
  

  .slots-col-wrapper {
    .slot-time {
      &:first-child,
      &:last-child {
        min-width: 38px !important;
  
        .time-span {
          display: none;
        }
  
        .after-half-marker:before,
        .before-half-marker:after {
          /* width: 2px; */
          display: none;
        }
      }
    }
  }
  
  .time-slot-selected{
        &.slot-time{
            &:first-child .before-half-marker, &:last-child .after-half-marker {
                display: none;
            }
            &:first-child .day-event-name{
                left: 1px;
            }
        }
  }
  
  .time-slot-selected-before-half, .time-slot-selected-after-half{
    &.slot-time{
        &:first-child .day-event-name{
            left: 1px;
        }
    }
  }

#room-calendar-side-accordion {
    .card {
        border-top: none;
        border-bottom: none;
        min-height: 46px;
        display: block;
    }
    .card-header {
        /* border: none; */
        min-height: 46px;
        margin-bottom: 0px;
        max-height: 46px;
        overflow: hidden;
        background: rgba($color-primary-hover, 0.07);
    }
    .room-option {
        min-height: 46px;
        max-height: 46px;
        overflow: hidden;
        position: relative;
        padding: 13px;
        &:after {
            content: '';
            position: absolute;
            height: 1px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background-color: $color-gray-1;
        }
    }
}

.accordian-room-heading {
    min-height: 50px;
    max-height: 50px;
    padding: 13px;
    // background: rgba(5, 93, 168 , 30%);
}

/*End of Style for Day Diary Component*/


#diary-view-section {
    position: relative;

    .diary-drag-container {
        //added to fix containment issue
        display: block;
        position: absolute;
        left: 0;
        top: 48px;
        bottom: 0;
        right: 0;
        z-index: -1
    }

    .msp-current-time {
        position: absolute;
        left: 0;
        width: 2px;
        height: calc(100% - 4px);
        z-index: 500;
        top: 3px;
        background-color: $color-danger;

        &::before {
            left: -3px;
        }
    }

    .slot-body-row-wrapper {
        &:hover {
            background-color: $color-primary;

            .d-table.border-bottom {
                background-color: $color-primary !important;
            }
        }
    }
}

.promp-header {
    min-height: 15.5px;
    max-height: 15.5px;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    .week-promp-name {
        padding-right: 10px;
    }
    &::-webkit-scrollbar {
        height: 2px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba($color-black, 0.3);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-secondary;
        outline: 1px solid $color-secondary-hover;
    }
}

.slots-wrapper.week-time-slot .head-week-wrapper {
    background-color: var(--day-bg-head-color);
}

.head-week-wrapper:after,
.body-week-wrapper:after {
    position: absolute;
    content: '';
    right: 0px;
    min-width: 1px;
    max-width: 1px;
    background-color: $color-gray-1;
    top: 0px;
    bottom: 0px;
}

.head-week-wrapper:after {
    background-color: $color-white;
}

.head-week-wrapper,
.body-week-wrapper {
    min-width: 216px;
    max-width: 216px;
}

.body-week-wrapper>.week-hour-mark-wrapper {
    min-height: 45px;
    max-height: 45px;
}

.week-hour-marker {
    min-width: 18px;
    max-width: 18px;
    height: 11px;
    z-index: 2;
    &::before {
        content: '';
        min-width: 1px;
        max-width: 1px;
        position: absolute;
        left: 0px;
        z-index: 6;
        bottom: 0px;
        height: 6px;
        display: inline-block;
        // background-color: $color-white;
    }
}

.week-event-name {
    position: absolute;
    z-index: 6;
    opacity: 0.6;
    bottom: 0px;
    font-size: 11px;
    background-color: var(--bg-color);
    width: calc((var(--event-size) * 18px));
    left: 0px;
}

.event-calander-view {
    max-width: 203px !important;
}
