.sidebar {
    max-height: 100vh;
    min-height: 100vh;
    overflow: initial;
    transition: 0.35s all;
    margin-left: 0rem;

    .nav-item {
        min-height: 42px;
        max-height: 42px;
    }
    .secondry-nav-items {
        .nav-item{
            min-height: 34px;
            max-height: 34px;
        }
       
    }
    .nav-item:last-child {
        margin-bottom: 0px;
    }
    &.slide{
        min-width: 12rem !important;
        min-height: 100vh;
    }
    #nav-list {
        overflow: hidden;
    }
}
.slide {
    margin-left: 0 !important;
    width: 14rem !important;
}

.sidebar-dark{

    .nav-item{
        .nav-link{
            padding: 0.35rem 1rem;

            i {
                width: 27px;
                height: 30px;
                font-size: 24px;
                line-height: 22px;
                text-align: center;
                color: rgba($color-white, 0.5);
            }
        }
    }

    .secondry-nav-items{
        .nav-item {
            span {
                display: inline !important;
                color: $color-black;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .nav-link {
                i {
                    font-size: 18px;
                }
                &:active, &:hover, &:hover i, &:active i{
                    color: $color-primary;
                }
            }

        }
    }


}

.secondry-nav-items{
    .nav-item{
        padding: 0.5rem;
        &.active{
            .nav-link{
                background-color: rgba($color-primary, 0.1);
            }
            .nav-link, .nav-link i{
                color:$color-primary;
            }

        }
        .nav-link {
            padding: 0.35rem 0.5rem;
            &:hover{
                background-color: rgba($color-primary, 0.1);
            }
            i{
                color: rgba($color-primary, 0.5);
                height: auto;
            }
        }
    }

   .nav-link {
        border-radius: 5px;
    }
    #secondry-collapsable-navs .nav-item .nav-link {
        padding: 0.35rem 0.5rem;
        display: flex !important;
        align-items: center;
    }
}

#secondry-collapsable-navs-wrapper.collapsing {
    -webkit-transition: none;
    transition: none;
    /* display: none; */
}

#secondry-collapsable-navs {
    overflow-y: auto;
    max-height: 300px;
    height: auto;
    min-height: 75px;
}

@media (max-height: 400px) {
    #secondry-collapsable-navs {
        max-height: calc(100vh - 150px);
        min-height: auto;
    }
}

#secondry-navs .secondry-nav-items {
    position: absolute;
    left: 63px;
    z-index: 997;
    bottom: 0px;
    box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 15%) !important;
    /* top: -300px; */
    min-width: 150px;
    padding-bottom: 45px;
}

.manage-btn-of-secondary-nav {
    position: absolute;
    bottom: 10px;
    background-color: $color-white;
    left: 12px;
}

.slide #secondry-navs .secondry-nav-items {
    left: 102%;
}

.secondry-sidebar-collapse-button {
    color: rgba(255, 255, 255, 0.3);
    background: none;
    border: 0;
    &:hover {
        color: $color-white;
    }
    &:focus {
        outline: none;
      }
    
}
.reseqeance-warning {
    border-radius: 5px;
    background-color: #d2effd;
}

.sidebar~#content-wrapper .fixed-btn-container,
.sidebar~.fixed-btn-container {
    left: 61px;
    z-index: 994;
    width: auto;
    transition: 0.2s left;
}

.slide.sidebar~#content-wrapper .fixed-btn-container,
.slide.sidebar~.fixed-btn-container {
    left: 12.21rem;
    width: auto;
    transition: 0.2s left;
}

.msp-data-list .grid-cell {
    justify-content: unset;
}

.msp-data-list .grid-table tr td:first-child>div.grid-cell {
    padding-left: 0;
}


// Inner Sider navigation 

#side-nav {
    width: 100%;
    max-width: 300px;

    nav {
        max-width: 280px;
        top: 0;
        width: 100%;
    }
    &>nav {
        position: sticky;
        top: 100px !important;
        z-index: 1;
    }
}


@media (max-width:767px){

    .sidebar {
        min-width: 14rem !important;
        width: 14rem !important;
        position: fixed;
        z-index: 10;
        margin-left: -14rem !important;
        &.slide {
            margin-left: 0 !important;
            z-index: 999;
            min-height: 100%;
            height: 100%;
        }
    }

    .sidebar-dark{
        .nav-item .nav-link i {
            font-size: 1rem !important;
        }
        .secondry-nav-items #secondry-collapsable-navs .nav-item span {
            display: inline-block !important;
            color: $color-black;
            white-space: nowrap;
            overflow: hidden;
            /* max-width: 100px; */
            width: calc(100% - 27px);
            text-overflow: ellipsis;
        }
    }
    .sidebar~#content-wrapper .fixed-btn-container,
    .sidebar~.fixed-btn-container {
        left: 0 !important;
    }

    // Inner Side Navigation 

    #side-nav {
        flex-basis: auto;
        max-width: 100% !important;
        top: 80px;
        z-index: 1;
        position: sticky;
        padding: 10px;
        background: $color-white;
        .nav {
            max-width: 100% !important;
            display: block !important;
            padding-bottom: 0 !important;
            overflow: auto;
            white-space: nowrap;
            margin-top: 0 !important;
             a, .nav-link {
                    display: inline-block !important;
                }

        }
    }
}

