.newpipelines,
.pipelinesDecision,
.todayActivities,
.todaysAndOverDue {
    max-height: 288px;
}
/* For pipeline */
.decisionpipeline-list {
    &.dv-overdue {
      background: #FCF5F5 !important;
  
      h6 a {
        color: #D06969 !important;
      }
  
      .dates {
        color: #C79B9B !important;
      }
    }
  }  

/* For decision pipeline */

.overduecolor {
    background-color: $color-danger;
}

.overdue-dates {
    color: #C79B9B !important;
}

.overdue-priority {
    color: #C79B9B !important;
}

.pipeline-prority {
    color: #a90423 !important;
    background: #efd2de !important;
}

.activity-priority {
    color: #f13b6d !important;
    background: #f3edee !important;
}


.today-view .bg-light {
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.new-todayoverdue-clss {
    margin-top: 7px;
}

.activity-type-other {
    color: #5eea83 !important;
    background: #eaf7ed !important;
}
/*---Suraj Pipeline Guest Room Facilities Start---*/
.chkPipelineGuestRoomFacility {
    padding-left: 25px;
    line-height: 1;
}

.chkPipelineGuestRoomFacilityDetailPage {
    line-height: 1;
    cursor: default;
}

.pipeline-guestroomfacility-displayingrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
}

/*---Suraj Pipeline Guest Room Facilities End---*/
/*---Suraj Guest Room Facilities Start---*/
.chkGuestRoomFacility {
    padding-left: 25px;
    line-height: 1;
}

.guestroomfacility-displayingrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
}

/*---Suraj Guest Room Facilities End---*/