.pmsconfig .nav-link {
    cursor: pointer;
}
.pms-availability {
    background-color: $color-gray-2 !important;
    height: calc(100vh - 81px);
    overflow: auto;
}

.pms-availability {
    background-color: $color-gray-2 !important;
    height: calc(100vh - 81px);
    overflow: auto;
}

/*PMS availability*/
.pms-availability>.card-header {
    background: transparent !important;
}

.pms-rooms-sec {
    max-width: 210px;
    width: 100%;
    box-shadow: 4px 0 13px rgba($color-black, .09);
    position: relative;
    z-index: 1;

    .room-name-h {
        display: flex;
        padding: 20px 15px;
        height: 97px;
        align-items: center;
        font-size: 18px;
        color: $color-primary;
        font-weight: 500;
    }

    ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border-top: 1px solid $color-gray-1;
            line-height: 1.25;
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0 15px;

            div {
                @include text-hidden;
            }
        }
    }
}

.pms-rooms-val {
    overflow: auto;

    .row {
        margin: 0;
        flex-wrap: nowrap;

        &:last-child {
            .col {
                border-bottom: 0;

                &:last-child {
                    border-radius: 0 0 .35rem 0;
                }

            }
        }

        &:first-child {
            .col {
                &:last-child {
                    border-radius: 0 .35rem 0 0;
                }
            }
        }

        .col {
            &:last-child {
                border-right: 0;
            }
        }


    }

    .col {
        border: 1px solid $color-white;
        border-top: 0;
        border-left: 0;
        height: 48px;
        align-items: center;
        justify-content: center;
        display: flex;
        background-color: #E5F6DF;
        font-weight: 500;
        padding: 0 20px;
        min-width: 77px;
    }

    .col.not-available {
        background-color: #FBD9D3;
    }

    .header {
        .col {
            border-color: $color-gray-2;
            height: unset;
            background-color: $color-white;
            flex-direction: column;
            padding: 20px;
        }

        .date {
            width: 2.25rem;
            height: 2.25rem;
            margin: auto;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 1.2;
            font-weight: 500;

            span {
                padding-bottom: 2px;
            }
        }

        .week-view-head {
            font-size: .875rem;
            text-transform: uppercase;
        }
    }
}

@media (max-width:767px){
    .pmsconfig>.col.col-auto{
        width: 100% !important;
    }
}