/* Page & Grid editor CSS start */

.field-drop {
    border: solid 1px $color-white;
    height: 50px;
    color: $color-white;
    visibility: hidden !important;
  
    &.ui-droppable-active {
      background: $color-primary;
      visibility: visible !important;
    }
  
    &.ui-droppable-hover {
      width: 50%;
      background: $color-success;
    }
  }
  

.field-collection-block {
    padding: 2px 10px 2px 10px;
    border-radius: 0.25rem;
    &>div {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: $color-white;
        background-clip: border-box;
        border: 1px solid $color-gray-1;
        border-radius: 0.35rem;
    }
}

.field-properties label {
    font-weight: bold;
}

.field-properties-sec {
    height: calc(100vh - 20px) !important;
}

.field-list {
    list-style: none;
}

.field-drag {
    padding: 4px;
    border-radius: 0.2rem;
    margin: 1px 0;
    cursor: grab;
    color: $color-light !important;
    background-color: $color-gray-1;
    z-index: 2;
}

.field-drag:active{
    cursor: grabbing;
    background: $light-sky-blue-2 !important;
}


.tab-item{
    
    padding: 4px;
    border-radius: 0.2rem;
    margin: 2px 0;
    cursor: pointer;
    color: $color-light;
    background-color: $color-gray-1;
    z-index: 2;
    @include text-hidden;

    &:active {
        cursor: grabbing;
        background: $light-sky-blue-2 !important;
    }

    &.parent-selected, &.tab-selected {
        box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.4) !important;
        outline: 0;
        color: $color-primary !important;
        border-color: $color-light-blue;
        z-index: 1;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    &.parent-selected {
        box-shadow: 0 0 0 0.2rem rgba($color-success-hover, 0.6) !important;
    }
}

.field-layout {
    height: calc(100vh - 142px);
    overflow: auto;
    background-color: $color-gray-1;
    padding-bottom: 3.6rem !important;
}

.field-block{
    padding: 0;
    border-radius: .25rem;

    .action-btn {
        .btn {
            position: absolute;
            top: 0;
            opacity: 0;
            transition: all .3s;
            &.btn-secondary {
                left: 2px;
                top: 12px;
                background: none;
                color: rgba($color-primary, 0.6) !important;
                border: 0 !important;
                z-index: 100;
                font-size: 14px;
            }
            &.btn-danger {
                right: 5px;
                background: none !important;
                border: 0 !important;
            }
            &.field-info {
                right: 20px;
                background: none !important;
                border: 0 !important;
                opacity: 1 !important;
            }
        }
    }
    .card{
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25) !important;
            outline: 0;
            color: $color-primary !important;
            border-color: $color-light-blue;
            z-index: 1;
            .action-btn .btn {
                opacity: 1;
            }
        }
    }
    .field-selected{
        &.card {
            box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.25) !important;
            outline: 0;
            color: $color-primary !important;
            border-color: $color-light-blue;
            z-index: 1;
        }
        &>.card-body {
            box-shadow: 0 0.15rem 1.75rem 0 rgba($color-light, 0.25) !important;
        }
    }
    label {
        color: $color-secondary-hover;
    }
}

.field-drag,
.card-title,
.group-name div {
    @include text-hidden;
}


.group-block {
    border-radius: 0.35rem;
    &.changed {
        box-shadow: 0 0 0 0.2rem $light-sky-blue-2 !important;
    }
}

.tab-layout {
    overflow: auto;
    background-color: $color-gray-1;
    padding-right: 3.25rem !important;

    .nav-link {
        pointer-events: none;
    }
}


.parent-tab-title {
    font-weight: bold;
    color: $color-secondary;
    font-size: 90%;
}

.mandate-field-page {
    border-left: 0.1rem solid $color-warning !important;
}

.page-editor {
    .component {
        width: 250px;
        max-width: 350px;
    }

    .component-layout .editor-left-sec {
        height: calc(100vh - 20px) !important;
        padding-top: 0.75rem !important;
    }
}

.grid-editor .editor-left-sec {
    width: 260px;
    max-width: 350px;
}

.editor-left-sec {
    height: calc(100vh - 130px);
    overflow: auto;
  
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $color-light-gray;
  
      &:hover {
        background: $color-secondary;
      }
    }
  }
  

.grid-editor-list {
    width: calc(100% - 350px);
}

.field-item{
    .btn-group-setting, .btn-unmerge-group {
        opacity: 0;
        transition: all .3s;
    }
    &:hover{
         .btn-group-setting, .btn-unmerge-group {
            opacity: 1;
        }
    }
}

.outline-border {
    border: 1px solid $color-gray-1 !important;
}

.grid-editor-sidebar {
    .column-list .field-item:active {
        cursor: grabbing !important;
        background: $light-sky-blue-2 !important;
    }
    .field-list .field-item {
        border-radius: 0.2rem;
        &:hover {
            color: $color-light;
            background-color: $color-light-hover;
            border-color: $color-gray-1;
        }
    }
}

.system-defined-field {
    background: $color-success-hover !important;
    border-color: $color-success-hover !important;
    color: $color-white !important;
}


.page-editor-tab, 
.grid-editor-tab {
  .nav-item {
    margin-top: 2px;
    margin-right: 2px;

    &:hover {
      background-color: $color-gray-2;
      border-radius: 0.2rem;
    }
  }

  .nav-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.7109375rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.page-editor-tab {
  .sub-tab-nav {
    background-color: $color-priority-low-bg;
    border-radius: 0.2rem;
  }
}


/* Ends */