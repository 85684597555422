
.advc-filter-sec {
    position: fixed;
    width: 500px;
    top: 0;
    right: 0;
    background: $color-white;
    z-index: 1040;
    height: 100%;
    margin-right: -500px;
    transition: all 0.2s ease-in;
    &.report-edit-page {
        z-index: 5;
        /* this z-index is gone down to avoid overlap of manage menu and report page filter Reports-> Single Report -> Edit Report -> Filter Report*/
    }
    &.active {
        margin-right: 0px !important;
    }

    .list-filter-edit {
        height: 0px;
        overflow: hidden;
        transition: all 0.3s;
    }
    &.edit-mode .list-filter-edit {
        height: auto !important;
    }


    .treeview-conditions{

        .filter-ul {
            border-left: dashed 1px;
            position: relative;
            padding: 20px 0 20px 20px;
            margin: 20px 0 10px 0;
            &::before {
                position: absolute;
                top: calc(50% - 10px);
                left: -10px;
                border: dashed 1px;
                padding: 1px 2px;
                font-size: 0.7em;
                font-weight: bold;
                background: $color-white;
                z-index: 2;
            }
    
            .filter-li{
                &:first-child {
                    position: absolute;
                    top: -18px;
                }
                &:last-child {
                    position: absolute;
                    bottom: -10px;
                }
    
            }
        }
    
        ul.condition-AND::before {
            border-color: $color-primary;
            color: $color-primary;
            content: "AND";
        }
        ul.condition-OR::before {
            border-color: $color-danger;
            color: $color-danger;
            content: "OR";
        }

        .close {
            position: absolute;
            top: 5px;
            right: 8px;
            font-size: 12px;
        }
    
    }
}

.advc-filter-sec.other-user-filter .btn-add-edit-filter[action-type="edit"],
.advc-filter-sec.system-define-filter .btn-add-edit-filter[action-type="edit"],
.advc-filter-sec.system-define-filter .btn-delete-filter,
.advc-filter-sec .filter-footer,
.advc-filter-sec .btn-save-filter,
.advc-filter-sec .btn-cancel-filter,
.advc-filter-sec.edit-mode .btn-add-edit-filter,
.advc-filter-sec.edit-mode .selected-filter-detail {
    display: none;
}

.advc-filter-sec.edit-mode .filter-footer,
.advc-filter-sec.edit-mode .btn-save-filter,
.advc-filter-sec.edit-mode .btn-cancel-filter {
    display: inline-block;
}

.advc-filter-sec .treeview-conditions>.filter-ul>.filter-li:last-child .btnDeleteCondition {
    display: none;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li .filter-ul {
    margin-top: 30px;
    margin-bottom: 30px;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li {
    list-style: none;
    position: relative;
}

.advc-filter-sec .treeview-conditions .filter-ul .filter-li::before {
    position: absolute;
    top: calc(50% + 2px);
    left: -20px;
    border: dashed 0.6px;
    width: 20px;
    content: " ";
}

.advc-filter-sec .treeview-conditions ul.condition-AND .filter-li::before,
.treeview-conditions ul.condition-AND {
    border-color: $color-primary;
}

.advc-filter-sec .treeview-conditions ul.condition-OR .filter-li::before,
.treeview-conditions ul.condition-OR {
    border-color: $color-danger;
}

#report-filter{
    &.advc-filter-sec {
        margin: 0;
        position: relative;
        height: auto;
        width: 100%;
        .filter-footer {
            display: block;
        }
    }
}


/*Advance Filter End*/
