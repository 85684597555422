/**captcha css starts */
.captchaDiv svg {
    border-radius: .5rem 0 0 .5rem;
}

.captchaImage {
    padding-right: 0;
    padding-bottom: 0;
}

.refreshCaptcha {
    border-radius: 0 .5rem .5rem 0;
    background-color: #c18e5b;
    border: 0;
    height: 50px;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
    i {
        color: $color-white;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

/** captcha css ends*/