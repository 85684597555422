/*======================OnBoard Process Page============================*/

#onboard {
    .carousel-indicators {
      position: relative;
    }
  
    .carousel-caption {
      position: relative;
      right: unset;
      bottom: unset;
      left: unset;
      z-index: 10;
      padding-top: 0;
      padding-bottom: 0;
      color: $color-white;
      text-align: center;
    }
  
    .carousel-indicators li {
      background: $color-primary;
      width: 10px;
      height: 10px;
      border: 0;
      border-radius: 50%;
    }
  
    .carousel-caption .icon {
      width: 80px;
      height: 80px;
      line-height: 74px;
      display: inline-block;
      font-size: 2rem;
    }
  
    .btn-primary {
      min-width: 200px;
    }
  
    .select-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
    }
  
    .update-img {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      text-align: center;
      padding: 6px;
      background: rgba($color-black, 0.45);
      color: $color-white;
    }
  }
  .profile-pic-onboard-container {
    width: 200px;
    border: 1px solid $color-gray-1;
    border-radius: 0.35rem;
    height: 200px;
}