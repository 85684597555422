/*==========Store===========*/

.store-app-search {
    max-width: 700px;
  }
  
  .store-app-list {
    .card-deck {
      .card {
        margin-top: 1rem;
        max-width: 320px;
        min-width: 240px;
  
        .card-img-top {
          text-align: center;
          padding: 2em 0;
  
          img {
            display: inline-block;
            height: 80px;
          }
        }
  
        .card-title {
          font-weight: 500;
        }
  
        .btn-outline-light {
          color: $color-light;
          background-color: $color-blue-tint;
          border-color: $color-blue-tint;
        }
      }
    }
  }
  
  #headingOne,
  #headingTwo,
  #headingThree {
    h6 {
      &[aria-expanded="true"] {
        color: $color-primary !important;
  
        .down-arrow {
          transform: rotate(180deg);
        }
      }
  
      &[aria-expanded="false"] {
        .down-arrow {
          transform: rotate(0deg);
        }
      }
  
      &.disabled {
        opacity: 0.4;
      }
    }
  }
  
  /*Added by suraj on 28Feb2022 for Opera Store*/
  [data-pagename="AppPrice"] {
    .card-deck {
      .card {
        min-width: 250px;
        max-width: 250px;
        margin-top: 1em;
      }
    }
  }
  
  .company-footer {
    color: $color-white;
  
    a {
      color: $color-white;
      max-width: 200px;
    }
  }
  
  #wrapper {
    overflow: hidden;
  }
  